import { Session } from '@opentok/client';
import { EventEmitter } from 'events';
import Publisher from '../internal/publisher';
import { Analytics, logAction, logVariation } from '../analytics';

declare interface ScreenPublisher {
  on(event: 'audioLevelUpdated', listener: (audioLevel: number) => void): this;
  on(event: 'started', listener: () => void): this;
  on(event: 'stopped', listener: () => void): this;
}

const NOT_SCREEN_SHARING_ERROR = 'Client is not screen sharing';

class ScreenPublisher extends EventEmitter {
  private _defaultTargetElement: HTMLElement;
  isScreenSharing: Boolean = false;

  start: (targetElement?: HTMLElement | string) => Promise<void>;
  stop: () => void;
  isVideoEnabled: () => boolean;
  isAudioEnabled: () => boolean;
  enableVideo: () => void;
  disableVideo: () => void;
  enableAudio: () => void;
  disableAudio: () => void;
  setDisabledImageURI: (imageURI: string) => void;

  constructor(
    session: Session,
    targetElement: HTMLElement,
    analytics: Analytics
  ) {
    super();

    this._defaultTargetElement = targetElement;
    let _publisher = new Publisher('screen');

    const _attachEventsToScreenPublisher = async (): Promise<void> => {
      _publisher.on('audioLevelUpdated', (audioLevel: number) => {
        this.emit('audioLevelUpdated', audioLevel);
      });

      _publisher.on('videoElementCreated', () => this.emit('started'));

      _publisher.on('destroyed', () => this.emit('stopped'));
    };

    this.start = async (
      targetElement?: HTMLElement | string
    ): Promise<void> => {
      if (!_publisher) {
        _publisher = new Publisher('screen');
      }

      const target = targetElement ?? this._defaultTargetElement;

      _attachEventsToScreenPublisher();

      const screenPub = await _publisher.initOTPublisher(target, {
        showControls: true,
        style: {
          buttonDisplayMode: 'off',
        },
      });

      screenPub?.element?.classList.add('OT_screenshare');
      this.isScreenSharing = true;
      return new Promise<void>((resolve, reject) => {
        session.publish(screenPub, error => {
          if (error) {
            reject(error);
          } else {
            resolve();
          }
        });
      });
    };

    this.stop = (): void => {
      _publisher?.destroyOTPublisher();
      // @ts-ignore
      _publisher = undefined;
      this.isScreenSharing = false;
    };

    this.isVideoEnabled = (): boolean =>
      this.isScreenSharing && _publisher?.isVideoEnabled();

    this.isAudioEnabled = (): boolean =>
      this.isScreenSharing && _publisher?.isAudioEnabled();

    this.enableVideo = (): void => {
      analytics.log(logAction.screenPublisherEnableVideo, logVariation.attempt);
      if (this.isScreenSharing) {
        _publisher?.enableVideo();
        analytics.log(
          logAction.screenPublisherEnableVideo,
          logVariation.success
        );
      } else {
        analytics.log(
          logAction.screenPublisherEnableVideo,
          logVariation.failure,
          {
            error: NOT_SCREEN_SHARING_ERROR,
          }
        );
      }
    };

    this.disableVideo = (): void => {
      analytics.log(
        logAction.screenPublisherDisableVideo,
        logVariation.attempt
      );
      if (this.isScreenSharing) {
        _publisher?.disableVideo();
        analytics.log(
          logAction.screenPublisherDisableVideo,
          logVariation.success
        );
      } else {
        analytics.log(
          logAction.screenPublisherDisableVideo,
          logVariation.failure,
          {
            error: NOT_SCREEN_SHARING_ERROR,
          }
        );
      }
    };

    this.enableAudio = (): void => {
      analytics.log(logAction.screenPublisherEnableAudio, logVariation.attempt);
      if (this.isScreenSharing) {
        _publisher?.enableAudio();
        analytics.log(
          logAction.screenPublisherEnableAudio,
          logVariation.success
        );
      } else {
        analytics.log(
          logAction.screenPublisherEnableAudio,
          logVariation.failure,
          {
            error: NOT_SCREEN_SHARING_ERROR,
          }
        );
      }
    };

    this.disableAudio = (): void => {
      analytics.log(
        logAction.screenPublisherDisableAudio,
        logVariation.attempt
      );
      if (this.isScreenSharing) {
        _publisher?.disableAudio();
        analytics.log(
          logAction.screenPublisherDisableAudio,
          logVariation.success
        );
      } else {
        analytics.log(
          logAction.screenPublisherDisableAudio,
          logVariation.failure,
          {
            error: NOT_SCREEN_SHARING_ERROR,
          }
        );
      }
    };

    this.setDisabledImageURI = (imageURI: string): void => {
      _publisher.setDisabledImageURI(imageURI);
    };
  }
}

export default ScreenPublisher;
