import OT from '@opentok/client';

import { Device } from '../types/device';
import { Analytics, logAction, logVariation } from '../analytics';
const analytics = new Analytics();

const getDevices = (): Promise<Device[] | undefined> => {
  return new Promise((resolve, reject) => {
    analytics.log(logAction.getDevices, logVariation.attempt);
    OT.getDevices((error, devices) => {
      if (error) {
        analytics.log(logAction.getDevices, logVariation.failure, { error });
        reject(error);
      }
      analytics.log(logAction.getDevices, logVariation.success);
      resolve(devices);
    });
  });
};
export default getDevices;
