import { EventEmitter } from 'events';
import LocalParticipant from '../internal/local-participant';

class LocalParticipantWrapper extends EventEmitter {
  id: string;
  name?: string;
  connectionData: string;
  isMe: true = true;
  initials?: string;

  constructor(localParticipant: LocalParticipant) {
    super();
    this.id = localParticipant.id;
    this.connectionData = localParticipant.connectionData;
    this.name = localParticipant.name;
    this.initials = localParticipant.initials;
  }
}

export default LocalParticipantWrapper;
