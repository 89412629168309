/**
 * This Speaker Detection algorithm is based on https://tokbox.com/developer/guides/customize-ui/js/#active_speaker
 * Some values had to be modified for better detection of the speaker as well as once they stop speaking
 */

import { EventEmitter } from 'events';

const SPEAKER_DETECTED_FIRST_TIME_INTERVAL = 100; // this is milliseconds
const LOW_AUDIO_DETECTED_INTERVAL = 100; // this is milliseconds
const MIN_AUDIO_LEVEL = 0;

type SpeakerChangeDetected = {
  isSpeaking: boolean;
};

type Activity = {
  timestamp: number;
  talking: boolean;
};

declare interface SpeakerDetection {
  emit(
    event: 'activeSpeakerStatusChanged',
    payload: SpeakerChangeDetected
  ): boolean;
  on(
    event: 'activeSpeakerStatusChanged',
    listener: (payload: SpeakerChangeDetected) => void
  ): this;
}

class SpeakerDetection extends EventEmitter {
  activity = {} as Activity;
  onAudioLevelUpdated(audioLevel: number) {
    const now = Date.now();
    const isTalking = audioLevel > MIN_AUDIO_LEVEL;

    if (isTalking) {
      //checking below if the activity object has been initialized
      if (Object.keys(this.activity).length === 0) {
        // this is the first time we are hearing the person talk
        // initializing the initial variable
        this.activity = { timestamp: now, talking: false };
      } else if (this.activity.talking) {
        // the person is currently talking
        this.activity.timestamp = now;
      } else if (
        now - this.activity.timestamp >
        SPEAKER_DETECTED_FIRST_TIME_INTERVAL
      ) {
        // This part filters out random short noise
        // The person is currently speaking if they have been speaking for awhile
        this.activity.talking = true;
        this.emit('activeSpeakerStatusChanged', {
          isSpeaking: true,
        });
      }
    } else if (
      Object.keys(this.activity).length !== 0 &&
      now - this.activity.timestamp > LOW_AUDIO_DETECTED_INTERVAL
    ) {
      // The person may have stopped talking but we give them a period of time before dispatching the event to remove CSS for this active speaker
      // (this takes into account pauses when one speaks)
      if (this.activity.talking) {
        this.emit('activeSpeakerStatusChanged', {
          isSpeaking: false,
        });
        // if the active speaker changes externally, the activity tracker gets reset
        this.reset();
      }
    }
  }

  reset() {
    this.activity = {} as Activity;
  }
}

export default SpeakerDetection;
