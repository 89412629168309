import { removeItem } from '../utils/array';

export default class HiddenTileTracker {
  readonly PARTICIPANT_DISPLAY_LIMIT: Number;
  _currentActiveSpeakerElement?: HTMLElement;
  hiddenElements: Array<HTMLElement> = [];
  visibleElements: Array<HTMLElement> = [];
  /*
   * Array of video elements ordered by display priority
   * i.e. first entry is the current active speaker or a new participant.
   * The last entry is the participant with longest time since speaking or joining
   */
  _elementsOrderedByDisplayPriority: Array<HTMLElement> = [];

  constructor(maxVideoParticipantsOnScreen: Number) {
    this.PARTICIPANT_DISPLAY_LIMIT = maxVideoParticipantsOnScreen;
  }

  _removeLastActiveVisibleElement() {
    let lastActiveVisibleElement = this.visibleElements[0];
    this.visibleElements.forEach(element => {
      if (
        this._elementsOrderedByDisplayPriority.indexOf(element) <
        this._elementsOrderedByDisplayPriority.indexOf(lastActiveVisibleElement)
      ) {
        lastActiveVisibleElement = element;
      }
    });
    removeItem(this.visibleElements, lastActiveVisibleElement);
    this.hiddenElements.push(lastActiveVisibleElement);
  }

  onActiveSpeakerChanged(element?: HTMLElement) {
    this._currentActiveSpeakerElement = element;
    if (element) {
      removeItem(this._elementsOrderedByDisplayPriority, element);
      this._elementsOrderedByDisplayPriority.push(element);

      if (this.hiddenElements.includes(element)) {
        removeItem(this.hiddenElements, this._currentActiveSpeakerElement);
        this.visibleElements.push(element);
      }

      if (this.visibleElements.length > this.PARTICIPANT_DISPLAY_LIMIT) {
        this._removeLastActiveVisibleElement();
      }
    }
  }

  onSubscriberCreated(element: HTMLElement) {
    if (this.visibleElements.length >= this.PARTICIPANT_DISPLAY_LIMIT) {
      this._removeLastActiveVisibleElement();
    }
    this.visibleElements.push(element);
    this._elementsOrderedByDisplayPriority.push(element);
  }

  onSubscriberRemoved(element: HTMLElement) {
    removeItem(this.hiddenElements, element);
    removeItem(this.visibleElements, element);
    removeItem(this._elementsOrderedByDisplayPriority, element);
    if (
      this.visibleElements.length < this.PARTICIPANT_DISPLAY_LIMIT &&
      this.hiddenElements.length > 0
    ) {
      const elementToShow = this.hiddenElements.pop();
      if (elementToShow) {
        this.visibleElements.push(elementToShow);
      }
    }
  }
}
