import UAParser from 'ua-parser-js';

/* Using a permissive value here to include large phones and any future large phones while excluding tablets
See common device sizes here: https://experienceleague.adobe.com/docs/target/using/experiences/vec/mobile-viewports.html?lang=en
E.g. Samsung Galaxy Note 5	480. iPhone 12 Pro Max: 428.
Note this is to be used with max-device-width query which uses CSS pixel width which takes into account pixel density.
*/
const MAX_MOBILE_WIDTH = 500;

const uaParser = new UAParser(navigator.userAgent);
const uaDevice = uaParser.getDevice();

const hasTouchScreen = (): boolean => {
  if ('maxTouchPoints' in navigator) {
    return navigator.maxTouchPoints > 0;
  }
  const query = window.matchMedia('(pointer:coarse)');
  return query.matches;
};

const hasMobileScreenWidth = (): boolean => {
  const query = window.matchMedia(`(max-device-width: ${MAX_MOBILE_WIDTH}px)`);
  return query.matches;
};

/* Determine whether device is a mobile.
 *  - First try to determine via user agent detection
 *  - If that fails then check for touchscreen and a mobile size screen
 */
export const isMobile = (): boolean => {
  if (uaDevice.type) {
    return uaDevice.type === 'mobile';
  }
  return hasMobileScreenWidth() && hasTouchScreen();
};
