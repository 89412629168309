import OT, { Device } from '@opentok/client';
import { EventEmitter } from 'events';
import Publisher from '../internal/publisher';
import { getTargetElementFromDOM } from '../utils/dom';
import { Analytics, logAction, logVariation } from '../analytics';
import { VideoFilter } from '../utils/types';

declare interface PreviewPublisher {
  on(event: 'audioLevelUpdated', listener: (audioLevel: number) => void): this;
  on(event: 'created', listener: () => void): this;
  on(event: 'destroyed', listener: () => void): this;
  on(event: 'accessAllowed', listener: () => void): this;
  on(event: 'accessDenied', listener: () => void): this;
  on(event: 'accessDialogOpened', listener: () => void): this;
  on(event: 'accessDialogClosed', listener: (reason: string) => void): this;
}

class PreviewPublisher extends EventEmitter {
  private _defaultTargetElement: HTMLElement | string;

  isVideoEnabled: () => boolean;
  isAudioEnabled: () => boolean;
  enableVideo: () => void;
  disableVideo: () => void;
  enableAudio: () => void;
  disableAudio: () => void;
  getVideoDevice: () => Device;
  setVideoDevice: (deviceId: string) => Promise<void>;
  getAudioDevice: () => Promise<Device>;
  setAudioDevice: (deviceId: string) => Promise<void>;
  destroy: () => void;
  previewMedia: (publisherOptions: {
    targetElement?: HTMLElement | string;
    publisherProperties?: OT.PublisherProperties;
  }) => Promise<void>;
  setVideoFilter: (videoFilter: VideoFilter) => Promise<void>;
  clearVideoFilter: () => Promise<void>;
  setDisabledImageURI: (imageURI: string) => void;

  constructor(defaultTargetElement: HTMLElement | string) {
    super();
    const analytics = new Analytics();
    this._defaultTargetElement = defaultTargetElement;

    const _publisher = new Publisher();

    const _attachEventsToPreviewPublisher = (): void => {
      _publisher.on('audioLevelUpdated', (audioLevel: number) => {
        this.emit('audioLevelUpdated', audioLevel);
      });

      _publisher.on('videoElementCreated', () => this.emit('created'));

      _publisher.on('destroyed', () => this.emit('destroyed'));

      _publisher.on('accessAllowed', () => this.emit('accessAllowed'));

      _publisher.on('accessDenied', () => this.emit('accessDenied'));

      _publisher.on('accessDialogOpened', () =>
        this.emit('accessDialogOpened')
      );

      _publisher.on('accessDialogClosed', (reason: string) =>
        this.emit('accessDialogClosed', reason)
      );
    };

    this.isVideoEnabled = (): boolean => _publisher.isVideoEnabled();

    this.isAudioEnabled = (): boolean => _publisher.isAudioEnabled();

    this.enableVideo = (): void => {
      analytics.log(
        logAction.previewPublisherEnableVideo,
        logVariation.attempt
      );
      _publisher.enableVideo();
      analytics.log(
        logAction.previewPublisherEnableVideo,
        logVariation.success
      );
    };

    this.disableVideo = (): void => {
      analytics.log(
        logAction.previewPublisherDisableVideo,
        logVariation.attempt
      );
      _publisher.disableVideo();
      analytics.log(
        logAction.previewPublisherDisableVideo,
        logVariation.success
      );
    };

    this.enableAudio = (): void => {
      analytics.log(
        logAction.previewPublisherEnableAudio,
        logVariation.attempt
      );
      _publisher.enableAudio();
      analytics.log(
        logAction.previewPublisherEnableAudio,
        logVariation.success
      );
    };

    this.disableAudio = (): void => {
      analytics.log(
        logAction.previewPublisherDisableAudio,
        logVariation.attempt
      );
      _publisher.disableAudio();
      analytics.log(
        logAction.previewPublisherDisableAudio,
        logVariation.success
      );
    };

    this.getVideoDevice = (): Device => _publisher.getVideoDevice();

    this.setVideoDevice = (deviceId: string): Promise<void> =>
      _publisher.setVideoDevice(deviceId);

    this.getAudioDevice = (): Promise<Device> => _publisher.getAudioDevice();

    this.setAudioDevice = (deviceId: string): Promise<void> =>
      _publisher.setAudioDevice(deviceId);

    this.destroy = (): void => {
      analytics.log(logAction.destroyPreviewPublisher, logVariation.attempt);
      _publisher.destroyOTPublisher();
      analytics.log(logAction.destroyPreviewPublisher, logVariation.success);
    };

    this.previewMedia = async (
      publisherOptions: {
        targetElement?: HTMLElement | string;
        publisherProperties?: OT.PublisherProperties;
      } = {}
    ): Promise<void> => {
      const { targetElement, publisherProperties } = publisherOptions;
      analytics.log(logAction.initPreviewPublisher, logVariation.attempt, {
        publisherProperties,
      });
      const target = getTargetElementFromDOM(
        targetElement ?? this._defaultTargetElement
      );
      try {
        _attachEventsToPreviewPublisher();
        await _publisher.initOTPublisher(target, publisherProperties);
        analytics.log(logAction.initPreviewPublisher, logVariation.success);
      } catch (error) {
        analytics.log(logAction.initPreviewPublisher, logVariation.failure, {
          error,
        });
        throw error;
      }
    };

    this.setVideoFilter = async (videoFilter: VideoFilter) => {
      try {
        analytics.log(logAction.setVideoFilter, logVariation.attempt);
        await _publisher.setVideoFilter(videoFilter);
        analytics.log(logAction.setVideoFilter, logVariation.success);
      } catch (error) {
        analytics.log(logAction.setVideoFilter, logVariation.failure, {
          error,
        });
        throw error;
      }
    };

    this.clearVideoFilter = async () => {
      analytics.log(logAction.clearVideoFilter, logVariation.attempt);
      try {
        await _publisher.clearVideoFilter();
        analytics.log(logAction.clearVideoFilter, logVariation.success);
      } catch (error) {
        analytics.log(logAction.clearVideoFilter, logVariation.failure, {
          error,
        });
        throw error;
      }
    };
    this.setDisabledImageURI = (imageURI: string): void => {
      _publisher.setDisabledImageURI(imageURI);
    };
  }
}

export default PreviewPublisher;
