import Room from './mp/room';
import PreviewPublisher from './mp/preview-publisher';
import getDevices from './mp/get-devices';
import getActiveAudioOutputDevice from './mp/get-active-audio-output-device';
import getAudioOutputDevices from './mp/get-audio-output-devices';
import setProxyUrl from './mp/set-proxy-url';
import setAudioOutputDevice from './mp/set-audio-output-device';
import hasMediaProcessorSupport from './mp/has-media-processor-support';
import hasEndToEndEncryptionSupport from './mp/has-end-to-end-encryption-support';
import { setLogLevel, logLevel } from './logging';
import { Device, DeviceKind } from './types/device';
const { publicVersion: version } = require('../package.json');

export {
  Room,
  PreviewPublisher,
  version,
  getActiveAudioOutputDevice,
  getDevices,
  getAudioOutputDevices,
  setAudioOutputDevice,
  setLogLevel,
  setProxyUrl,
  hasMediaProcessorSupport,
  hasEndToEndEncryptionSupport,
  logLevel,
  Device,
  DeviceKind,
};
