export type ExternalPromiseResolver = {
  promise: Promise<unknown>;
  resolver: () => void;
  rejector: () => void;
};

export const createExternalPromiseResolver = (): ExternalPromiseResolver => {
  let resolver, rejector;
  const promise = new Promise((resolve, reject) => {
    resolver = resolve;
    rejector = reject;
  });
  // We must catch the promise otherwise jest will fail
  promise.catch(() => {});
  return {
    promise,
    // @ts-ignore
    resolver,
    // @ts-ignore
    rejector,
  };
};
