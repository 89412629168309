import {
  Stream,
  Subscriber as OTSubscriber,
  Session,
  OTError,
} from '@opentok/client';
import { EventEmitter } from 'events';
import Subscriber from '../internal/subscriber';
import { Analytics, logAction, logVariation } from '../analytics';

declare interface ScreenSubscriber {
  on(event: 'audioLevelUpdated', listener: (audioLevel: number) => void): this;
  on(event: 'created', listener: () => void): this;
  on(event: 'destroyed', listener: () => void): this;
}

class ScreenSubscriber extends EventEmitter {
  // @ts-ignore @TODO: Verify whether _stream variable required for this class?
  private _stream: Stream;

  id: string;
  getSubscriberElement: () => HTMLElement | undefined;
  isVideoEnabled: () => boolean;
  isAudioEnabled: () => boolean;
  enableVideo: () => void;
  disableVideo: () => void;
  enableAudio: () => void;
  disableAudio: () => void;
  setDisabledImageURI: (imageURI: string) => void;

  constructor(
    stream: Stream,
    otSubscriber: OTSubscriber,
    analytics: Analytics
  ) {
    super();
    this._stream = stream;
    const _subscriber = new Subscriber(otSubscriber);
    otSubscriber.element?.classList.add('MP_screenshare_subscriber');

    _subscriber.on('audioLevelUpdated', (audioLevel: number) => {
      this.emit('audioLevelUpdated', audioLevel);
    });

    _subscriber.on('destroyed', () => this.emit('destroyed'));

    _subscriber.on('videoElementCreated', () => {
      this.emit('created');
    });

    this.id = _subscriber.id as string;

    this.getSubscriberElement = () => _subscriber?.getSubscriberElement();

    this.isVideoEnabled = () => _subscriber?.isVideoEnabled();

    this.isAudioEnabled = () => _subscriber?.isAudioEnabled();

    this.enableVideo = (): void => {
      analytics.log(
        logAction.screenSubscriberEnableVideo,
        logVariation.attempt
      );
      if (_subscriber) {
        _subscriber.enableVideo();
        analytics.log(
          logAction.screenSubscriberEnableVideo,
          logVariation.success
        );
      } else {
        analytics.log(
          logAction.screenSubscriberEnableVideo,
          logVariation.failure
        );
      }
    };

    this.disableVideo = (): void => {
      analytics.log(
        logAction.screenSubscriberDisableVideo,
        logVariation.attempt
      );
      if (_subscriber) {
        _subscriber.disableVideo();
        analytics.log(
          logAction.screenSubscriberDisableVideo,
          logVariation.success
        );
      } else {
        analytics.log(
          logAction.screenSubscriberDisableVideo,
          logVariation.failure
        );
      }
    };

    this.enableAudio = (): void => {
      analytics.log(
        logAction.screenSubscriberEnableAudio,
        logVariation.attempt
      );
      if (_subscriber) {
        _subscriber.enableAudio();
        analytics.log(
          logAction.screenSubscriberEnableAudio,
          logVariation.success
        );
      } else {
        analytics.log(
          logAction.screenSubscriberEnableAudio,
          logVariation.failure
        );
      }
    };

    this.disableAudio = (): void => {
      analytics.log(
        logAction.screenSubscriberDisableAudio,
        logVariation.attempt
      );
      if (_subscriber) {
        _subscriber.disableAudio();
        analytics.log(
          logAction.screenSubscriberDisableAudio,
          logVariation.success
        );
      } else {
        analytics.log(
          logAction.screenSubscriberDisableAudio,
          logVariation.failure
        );
      }
    };

    this.setDisabledImageURI = (imageURI: string): void => {
      _subscriber.setDisabledImageURI(imageURI);
    };
  }
}

export default ScreenSubscriber;

/* We can't use a promise here because the videoElementCreated event may be fired between
 * calling resolve() and the success handler being executed. Instead we use a synchronous callback.
 */
export const createScreenSubscriber = (
  session: Session,
  stream: Stream,
  analytics: Analytics,
  callback: (error?: OTError, subscriber?: ScreenSubscriber) => void
): void => {
  analytics.log(logAction.createScreenSubscriber, logVariation.attempt);
  const _otSubscriber = session.subscribe(
    stream,
    'layoutContainer',
    {
      insertMode: 'append',
      style: {
        buttonDisplayMode: 'off',
        nameDisplayMode: 'off',
        videoDisabledDisplayMode: 'off',
      },
    },
    error => {
      if (error) {
        analytics.log(logAction.createScreenSubscriber, logVariation.failure);
        callback(error);
      } else {
        const screenSubscriber = new ScreenSubscriber(
          stream,
          _otSubscriber,
          analytics
        );
        analytics.log(logAction.createScreenSubscriber, logVariation.success);
        callback(undefined, screenSubscriber);
      }
    }
  );
};
