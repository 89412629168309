export const getTargetElementFromDOM = (
  element?: HTMLElement | string
): HTMLElement => {
  if (element instanceof HTMLElement) {
    return element;
  } else if (typeof element === 'string') {
    const ele = document.getElementById(element);
    if (!ele) throw new Error(`Invalid element. Element not found: ${element}`);
    return ele;
  } else {
    throw new Error(`element must be string or HTMLElement. Found: ${element}`);
  }
};

export const createDiv = (
  id: string,
  style: Record<string, string> = {}
): HTMLElement => {
  const element: HTMLElement = document.createElement('div');
  element.id = id;

  Object.entries(style).forEach(([key, value]) => {
    element.style.setProperty(key, value);
  });
  return element;
};
