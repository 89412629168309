/* eslint-disable no-console */
export const logLevel = {
  NONE: 0,
  ERROR: 1,
  WARN: 2,
  INFO: 3,
  DEBUG: 4,
} as const;

export type LogLevel = typeof logLevel[keyof typeof logLevel];

let currentLogLevel: LogLevel = logLevel.NONE;

export const setLogLevel = (level: LogLevel) => {
  if (Object.values(logLevel).includes(level)) {
    currentLogLevel = level;
  } else {
    console.error('Attempting to set invalid log level: ', level);
  }
};

export const getLogLevel = () => currentLogLevel;

export const logger = {
  error: (...args: any) => {
    if (currentLogLevel >= logLevel.ERROR) {
      console.error(...args);
    }
  },
  warn: (...args: any) => {
    if (currentLogLevel >= logLevel.WARN) {
      console.warn(...args);
    }
  },
  info: (...args: any) => {
    if (currentLogLevel >= logLevel.INFO) {
      console.info(...args);
    }
  },
  debug: (...args: any) => {
    if (currentLogLevel >= logLevel.DEBUG) {
      console.debug(...args);
    }
  },
};
