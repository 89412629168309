import OT, { Connection } from '@opentok/client';
import { EventEmitter } from 'events';
import LayoutManager, { LayoutOptions } from '../mp/layout-manager';
import CameraPublisher from '../mp/camera-publisher';
import CameraSubscriber, {
  createCameraSubscriber,
} from '../mp/camera-subscriber';
import ScreenPublisher from '../mp/screen-publisher';
import ScreenSubscriber, {
  createScreenSubscriber,
} from '../mp/screen-subscriber';
import ActiveSpeakerTracker, {
  ActiveSpeakerInfo,
} from '../mp/active-speaker-tracker';
import { getTargetElementFromDOM, createDiv } from '../utils/dom';
import Participant from './participant';
import ParticipantWrapper from '../mp/participant';
import { RoomProperties, LayoutMode, IceConfig } from '../utils/types';
import { Analytics, logAction, logVariation } from '../analytics';
import { deleteAllEntries, removeUndefinedProperties } from '../utils/object';
import { SignalOptions, SignalEvent } from '../types/signaling';
import { logger } from '../logging';
import { isMobile } from '../utils/is-mobile';
import {
  createExternalPromiseResolver,
  ExternalPromiseResolver,
} from '../utils/promise';
import LocalParticipant from './local-participant';
import LocalParticipantWrapper from '../mp/local-participant';

const PARTICIPANT_NAME_RECEIVED_TIMEOUT = 300;

declare interface Room {
  on(event: 'connected', listener: () => void): this; // session connected event
  on(event: 'disconnected', listener: (reason: string) => void): this; // session disconnected event
  on(event: 'reconnecting', listener: () => void): this; // session reconnecting event
  on(event: 'reconnected', listener: () => void): this; // session reconnected event
  on(event: 'cameraPublisherMediaDisabled', listener: () => void): this;
  on(
    event: 'cameraSubscriberVideoDisabled',
    listener: (id: string) => void
  ): this;
  on(
    event: 'participantJoined',
    listener: (participant: ParticipantWrapper) => void
  ): this;
  on(
    event: 'participantLeft',
    listener: (participant: ParticipantWrapper, reason: string) => void
  ): this;
  on(
    event: 'activeSpeakerChanged',
    listener: (participant: ParticipantWrapper) => void
  ): this;
  on(event: 'signal', listener: (event: SignalEvent) => void): this;
}

type CameraSubscriberById = Record<string, CameraSubscriber>;
type ScreenSubscriberById = Record<string, ScreenSubscriber>;

class Room extends EventEmitter {
  apiKey: string;
  roomId: string;
  token: string;
  iceConfig?: IceConfig;
  encryptionEnabled?: boolean;
  roomContainer: HTMLElement | string;
  managedLayoutOptions?: LayoutOptions;
  mediaShutoffThreshold?: number;
  maxVideoParticipantsOnScreen?: number;
  useMobileLayout: boolean;
  participants: Record<string, Participant | LocalParticipant> = {};
  participantWrappers: Record<
    string,
    ParticipantWrapper | LocalParticipantWrapper
  > = {};
  camera: CameraPublisher;
  screen: ScreenPublisher;
  participantName: string;
  participantInitials: string;

  private _waitUntilJoined: ExternalPromiseResolver;
  private _analytics: Analytics;
  private _session: OT.Session;
  private _layoutManager: LayoutManager;
  private _activeSpeakerTracker: ActiveSpeakerTracker;

  private _cameraSubscribers: CameraSubscriberById = {};
  private _screenSubscribers: ScreenSubscriberById = {};
  private _visibleParticipantsCount: number = 0;

  constructor(roomProperties: RoomProperties) {
    super();
    logger.debug('Initializing room with properties', roomProperties);
    const {
      apiKey,
      sessionId,
      token,
      roomContainer,
      mediaShutoffThreshold,
      maxVideoParticipantsOnScreen,
      managedLayoutOptions = {},
      participantName = '',
      iceConfig,
      participantInitials = '',
    } = roomProperties;

    this._analytics = new Analytics({ sessionId, apiKey });
    this._analytics.log(logAction.initRoom, logVariation.attempt, {
      roomProperties,
    });

    if (!apiKey || !sessionId || !token) {
      const error =
        'Invalid credentials. Check the API_KEY, TOKEN, SESSION_ID passed';
      this._analytics.log(logAction.initRoom, logVariation.failure, { error });
      logger.error(error);
      throw new Error(error);
    }

    this.apiKey = apiKey;
    this.roomId = sessionId;
    this.token = token;
    this.iceConfig = iceConfig;
    this.encryptionEnabled =
      typeof roomProperties.encryptionSecret === 'string';
    this.roomContainer = roomContainer || this._createDefaultRoomContainer();
    this.mediaShutoffThreshold = mediaShutoffThreshold;

    this._waitUntilJoined = createExternalPromiseResolver();

    if (
      (maxVideoParticipantsOnScreen !== undefined &&
        typeof maxVideoParticipantsOnScreen !== 'number') ||
      (typeof maxVideoParticipantsOnScreen === 'number' &&
        maxVideoParticipantsOnScreen < 0)
    ) {
      logger.warn(
        'Warning: maxVideoParticipantsOnScreen must be a number greater than or equal to 0. Received: ',
        maxVideoParticipantsOnScreen
      );
    } else {
      this.maxVideoParticipantsOnScreen = maxVideoParticipantsOnScreen;
    }

    this.participantName = participantName;
    this.participantInitials = participantInitials;

    const {
      deviceLayoutMode = 'auto',
      ...layoutOptions
    } = managedLayoutOptions;
    this.useMobileLayout =
      deviceLayoutMode === 'mobile' ||
      (deviceLayoutMode === 'auto' && isMobile());

    logger.debug('Initializing room with layout manager');
    this.managedLayoutOptions = {
      layoutMode: 'grid',
      isMobileLayout: this.useMobileLayout,
      ...layoutOptions,
    };

    this._session = OT.initSession(this.apiKey, this.roomId, {
      iceConfig: this.iceConfig,
      encryptionSecret: roomProperties.encryptionSecret,
    });

    this._layoutManager = new LayoutManager(
      this.roomContainer,
      this.managedLayoutOptions,
      maxVideoParticipantsOnScreen
    );
    this._layoutManager.on('layoutComplete', () => {
      this._setOptimalSubscriberResolutions();
    });
    this._layoutManager.on(
      'cameraSubscriberHidden',
      this._onCameraSubscriberHiddenByLayoutManager
    );
    this._layoutManager.on(
      'cameraSubscriberDisplayed',
      this._onCameraSubscriberDisplayedByLayoutManager
    );

    this.camera = new CameraPublisher(
      this._session,
      this._getDefaultPublisherElement(),
      this._analytics,
      this.participantName,
      this.participantInitials
    );

    this.screen = new ScreenPublisher(
      this._session,
      this._getDefaultPublisherElement('screen'),
      this._analytics
    );

    this._activeSpeakerTracker = new ActiveSpeakerTracker();

    this._activeSpeakerTracker.on(
      'activeSpeakerChanged',
      this._onActiveSpeakerChanged
    );

    this._activeSpeakerTracker.on(
      'activeSpeakerStatusChanged',
      this._onActiveSpeakerStatusChanged
    );

    this._analytics.log(logAction.initRoom, logVariation.success);
  }

  _attachOTSessionEventListeners = () => {
    this._session.on('sessionConnected', () => {
      logger.debug('Connected to session');
      this.emit('connected');
    });

    this._session.on('sessionDisconnected', event => {
      const { reason } = event;
      logger.debug(`SessionDisconnect, reason: ${reason}`);
      if (this.camera) {
        this.camera.destroyCameraPublisher();
      }

      if (this.screen) {
        this.screen.stop();
      }

      this.emit('disconnected', reason);
    });

    this._session.on('sessionReconnecting', () => {
      logger.debug('Session Reconnecting');
      this.emit('reconnecting');
    });

    this._session.on('sessionReconnected', () => {
      logger.debug('Session Reconnected');
      this.emit('reconnected');
    });

    this._session.on('streamCreated', async event => {
      // Wait until we have joined call before subscribing
      try {
        await this._waitUntilJoined.promise;
      } catch (e) {
        // Join failed, disregard stream
        return;
      }

      const { stream } = event;
      const {
        connection: { connectionId },
      } = stream;

      const participant = this.participants[connectionId];

      if (participant && !participant.isMe) {
        logger.debug(`Stream created for participant: ${connectionId}`);
        this._onParticipantStreamCreated(
          this._session,
          stream,
          participant,
          this._shouldEnableParticipantVideo()
        );
      } else {
        logger.info(
          `No participant found for new stream with connection id ${connectionId}`
        );
      }
    });

    this._session.on('streamDestroyed', event => {
      const { stream } = event;
      const {
        connection: { connectionId },
      } = stream;

      const participant = this.participants[connectionId];

      if (participant) {
        logger.debug(`Stream destroyed for participant: ${connectionId}`);
        this._onParticipantStreamDestroyed();
      }

      if (!this.useMobileLayout && this._shouldEnableParticipantVideo()) {
        logger.info('Subscribing to all participants video');
        Object.values(this.participants).forEach(participant => {
          if (!participant.isMe) {
            participant.camera?.enableVideo();
          }
        });
      }
    });

    this._session.on('connectionCreated', async event => {
      const { connection } = event;
      const { participant, participantWrapper } = this._createParticipant(
        connection
      );
      if (!this._isMe(connection)) {
        try {
          await this._sendParticipantName(connection);
        } catch (error) {
          logger.warn(
            'Attempted to send the participant name to a participant that is already disconnected.'
          );
        }

        setTimeout(() => {
          // Check if the participant is still connected.
          if (!this.participants[participant.id]) {
            return;
          }

          if (this.participants[participant.id].name === null) {
            logger.info(
              'Timeout waiting for participant name, using empty name.'
            );
            this._onParticipantNameReceived(participantWrapper, '');
          }
        }, PARTICIPANT_NAME_RECEIVED_TIMEOUT);
      }
    });

    this._session.on('connectionDestroyed', event => {
      const { connection, reason } = event;
      const participantId = connection.connectionId;
      const participant = this.participants[participantId];
      if (participant && !participant.isMe) {
        const { isParticipantJoinedSent } = participant;
        const participantWrapper = this.participantWrappers[participantId];
        delete this.participantWrappers[participantId];
        delete this.participants[participantId];
        if (isParticipantJoinedSent) {
          logger.info(`Participant left: ${participantWrapper}`);
          this.emit('participantLeft', participantWrapper, reason);
        }
      }
    });

    this._session.on('signal', this._onSignalReceived);
  };

  _createParticipant = (
    connection: Connection
  ): {
    participant: Participant | LocalParticipant;
    participantWrapper: ParticipantWrapper | LocalParticipantWrapper;
  } => {
    let participant, participantWrapper;
    if (this._isMe(connection)) {
      ({ participant, participantWrapper } = this._createLocalParticipant(
        connection,
        this.participantName
      ));
    } else {
      ({ participant, participantWrapper } = this._createRemoteParticipant(
        connection
      ));
    }
    this.participants[participant.id] = participant;
    this.participantWrappers[participant.id] = participantWrapper;
    return {
      participant,
      participantWrapper,
    };
  };

  _createLocalParticipant = (
    connection: Connection,
    name?: string
  ): {
    participant: LocalParticipant;
    participantWrapper: LocalParticipantWrapper;
  } => {
    const participant = new LocalParticipant(connection, name);
    participant.setInitials(this.participantInitials);
    const participantWrapper = new LocalParticipantWrapper(participant);

    return {
      participant,
      participantWrapper,
    };
  };

  _createRemoteParticipant = (
    connection: Connection
  ): {
    participant: Participant;
    participantWrapper: ParticipantWrapper;
  } => {
    const participant = new Participant(connection);
    const participantWrapper = new ParticipantWrapper(participant);

    return {
      participant,
      participantWrapper,
    };
  };

  _removeOTSessionEventListeners = () => {
    this._session.off();
  };

  _createDefaultRoomContainer = () => {
    const element = createDiv('roomContainer', {
      width: '100vw',
      height: '100vh',
      position: 'relative',
    });
    document.body.appendChild(element);
    return element;
  };

  _getDefaultPublisherElement = (
    type: 'camera' | 'screen' = 'camera'
  ): HTMLElement => {
    const {
      managedLayoutOptions: {
        screenPublisherContainer,
        cameraPublisherContainer,
      } = {},
    } = this;
    const customContainer =
      type === 'camera' ? cameraPublisherContainer : screenPublisherContainer;
    if (customContainer) {
      return getTargetElementFromDOM(customContainer);
    }
    // default target is layout managed div
    return this._layoutManager.getLayoutContainerElement();
  };

  _connect = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      this._session.connect(this.token, error => {
        if (error) {
          reject(error);
        } else {
          this._analytics.update(
            this._session.sessionId,
            this._session.connection!.connectionId,
            this.apiKey
          );
          logger.info('Session connected');
          resolve();
        }
      });
    });
  };

  _isMe = (connection: OT.Connection | null): boolean =>
    !!connection &&
    this._session?.connection?.connectionId === connection.connectionId;

  get participantId() {
    return this._session?.connection?.connectionId ?? '';
  }

  get participantConnectionData() {
    return this._session?.connection?.data ?? '';
  }

  _setOptimalSubscriberResolutions = () => {
    Object.values(this._cameraSubscribers).forEach(
      (cSubscriber: CameraSubscriber) => {
        cSubscriber.setOptimalPreferredResolution();
      }
    );
  };

  _getCameraSubscriberElement = (subscriberId?: string) => {
    if (!subscriberId) {
      return undefined;
    }
    return this._cameraSubscribers[subscriberId].getSubscriberElement();
  };

  _onParticipantNameReceived = (
    participantWrapper: ParticipantWrapper | LocalParticipantWrapper,
    name: string
  ) => {
    const participantId = participantWrapper.id;
    if (this.participants[participantId]) {
      // Set the name
      this.participants[participantId].name = name;
      const participant = this.participants[participantId];

      // Make sure we emit participantJoined only one time.
      if (!participant.isMe && !participant.isParticipantJoinedSent) {
        participant.isParticipantJoinedSent = true;
        this.emit('participantJoined', participantWrapper);
      }
    }
  };

  _onSignalReceived = (event: {
    type?: string;
    data?: string;
    from: OT.Connection | null;
  }) => {
    const participantSenderWrapper = event.from
      ? this.participantWrappers[event.from.connectionId]
      : null;
    if (
      participantSenderWrapper &&
      event.type === 'signal:MP_INTERNAL_PARTICIPANT_NAME' &&
      !this._isMe(event.from)
    ) {
      this._onParticipantNameReceived(
        participantSenderWrapper,
        event.data ?? ''
      );
      return;
    }
    const signalData = {
      type: event.type,
      data: event.data,
      from: participantSenderWrapper,
      isSentByMe: this._isMe(event.from),
    };
    this.emit('signal', signalData);
  };

  _onCameraSubscriberCreated = (
    stream: OT.Stream,
    cameraSubscriber: CameraSubscriber
  ) => {
    this._cameraSubscribers[cameraSubscriber.id] = cameraSubscriber;
    const element = cameraSubscriber.getSubscriberElement();
    cameraSubscriber.on('created', () => {
      this._layoutManager.onCameraSubscriberCreated(element);
    });

    cameraSubscriber.on('destroyed', () => {
      this._activeSpeakerTracker.onCameraSubscriberDestroyed(
        cameraSubscriber.id
      );
      this._layoutManager?.onCameraSubscriberDestroyed(element);
      delete this._cameraSubscribers[cameraSubscriber.id];
    });
  };

  _onScreenSubscriberCreated = (
    stream: OT.Stream,
    screenSubscriber: ScreenSubscriber
  ) => {
    this._screenSubscribers[screenSubscriber.id] = screenSubscriber;
    screenSubscriber.on('created', () => {
      this._layoutManager.onScreenSubscriberCreated(screenSubscriber.id);
    });

    screenSubscriber.on('destroyed', () => {
      const subscriberId = screenSubscriber.id;
      this._layoutManager.onScreenSubscriberDestroyed(subscriberId);
      delete this._screenSubscribers[subscriberId];
    });
  };

  _onActiveSpeakerStatusChanged = (payload: { isSpeaking: boolean }) => {
    this._layoutManager.onActiveSpeakerStatusChanged(payload);
  };

  _onActiveSpeakerChanged = ({
    newActiveSpeaker,
    previousActiveSpeaker,
  }: {
    newActiveSpeaker: ActiveSpeakerInfo;
    previousActiveSpeaker: ActiveSpeakerInfo;
  }) => {
    const newActiveSpeakerElement = this._getCameraSubscriberElement(
      newActiveSpeaker.subscriberId
    );

    const previousActiveSpeakerElement = this._getCameraSubscriberElement(
      previousActiveSpeaker.subscriberId
    );

    this._layoutManager.onActiveSpeakerChanged({
      newActiveSpeakerElement,
      previousActiveSpeakerElement,
    });
    this.emit('activeSpeakerChanged', newActiveSpeaker.participant);
  };

  _sendParticipantName = (to?: OT.Connection): Promise<void> =>
    new Promise((resolve, reject) => {
      this._session.signal(
        removeUndefinedProperties({
          to,
          type: 'MP_INTERNAL_PARTICIPANT_NAME',
          data: this.participantName,
        }),
        err => {
          if (err) {
            reject(err);
          }
          resolve();
        }
      );
    });

  _shouldEnableParticipantVideo = () =>
    this.maxVideoParticipantsOnScreen === undefined ||
    this._visibleParticipantsCount < this.maxVideoParticipantsOnScreen;

  _shouldDisablePublisherMedia = () =>
    !!this.mediaShutoffThreshold &&
    Object.keys(this.participants).length > this.mediaShutoffThreshold;

  _onParticipantStreamDestroyed = async () => {
    this._visibleParticipantsCount =
      this._visibleParticipantsCount > 0
        ? this._visibleParticipantsCount - 1
        : 0;
  };

  _onParticipantStreamCreated = async (
    session: OT.Session,
    stream: OT.Stream,
    participant: Participant,
    shouldEnableVideo: boolean
  ): Promise<void> => {
    const { videoType } = stream;

    if (videoType === 'screen') {
      createScreenSubscriber(
        session,
        stream,
        this._analytics,
        (err, screenSubscriber) => {
          if (!err && screenSubscriber) {
            participant.setScreenSubscriber(screenSubscriber);
            this._onScreenSubscriberCreated(stream, screenSubscriber);
          }
        }
      );
    } else {
      createCameraSubscriber(
        session,
        stream,
        shouldEnableVideo,
        this._analytics,
        (err, cameraSubscriber) => {
          if (!err && cameraSubscriber) {
            participant.setCameraSubscriber(cameraSubscriber);
            participant.setInitials(stream.initials);
            this._onCameraSubscriberCreated(stream, cameraSubscriber);
            cameraSubscriber.on(
              'audioLevelUpdated',
              (audioLevel, movingAvg) => {
                this._activeSpeakerTracker.onSubscriberAudioLevelUpdated({
                  subscriberId: cameraSubscriber.id,
                  movingAvg,
                  participant,
                  audioLevel,
                });
              }
            );
          }
        }
      );
      if (!shouldEnableVideo) {
        this.emit('cameraSubscriberVideoDisabled', participant.id);
      }
      this._visibleParticipantsCount = this._visibleParticipantsCount + 1;
    }
  };

  _onCameraSubscriberHiddenByLayoutManager = (subscriberId: string) => {
    const cameraSubscriber = this._cameraSubscribers[subscriberId];
    if (cameraSubscriber) {
      logger.debug(
        'Camera subscriber to be hidden in layout, unsubscribing to video: ',
        cameraSubscriber.id
      );
      cameraSubscriber.disableVideo();
    }
  };
  _onCameraSubscriberDisplayedByLayoutManager = (subscriberId: string) => {
    const cameraSubscriber = this._cameraSubscribers[subscriberId];
    if (cameraSubscriber) {
      logger.debug(
        'Camera subscriber to be displayed in layout, subscribing to video: ',
        cameraSubscriber.id
      );
      cameraSubscriber.enableVideo();
      const videoElement = this._getCameraSubscriberElement(subscriberId);
      if (videoElement) {
        this._layoutManager._removeSubscriberOnLoad(videoElement);
      }
    }
  };

  join = async (
    publisherOptions: {
      targetElement?: HTMLElement | string;
      publisherProperties?: OT.PublisherProperties;
    } = {}
  ): Promise<void> => {
    const { targetElement, publisherProperties = {} } = publisherOptions;
    publisherProperties.name = this.participantName;

    logger.info('Joining room');
    this._analytics.log(logAction.joinRoom, logVariation.attempt, {
      publisherOptions,
    });

    this._attachOTSessionEventListeners();

    let publisherCreated = false;
    let connected = false;

    const joinFailureCleanup = () => {
      this._waitUntilJoined.rejector();
      this._cleanupOnDisconnect();
      if (publisherCreated) {
        this.camera.destroyCameraPublisher();
      }
      if (connected) {
        this._session.disconnect();
      }
    };

    try {
      this.camera.on('destroyed', this._onCameraPublisherDestroyed);
      await this.camera.initPublisher({
        targetElement,
        publisherProperties,
      });
      publisherCreated = true;
    } catch (error) {
      logger.error(
        'Failed to join room: could not initialize camera publish.',
        error
      );
      this._analytics.log(logAction.joinRoom, logVariation.failure, {
        error,
      });
      joinFailureCleanup();
      throw error;
    }

    try {
      await this._connect();
      connected = true;
    } catch (error) {
      logger.error('Failed to join room: could not connect to session.', error);
      this._analytics.log(logAction.joinRoom, logVariation.failure, {
        error,
      });
      joinFailureCleanup();
      throw error;
    }

    if (this._shouldDisablePublisherMedia()) {
      this.camera.disableAudio();
      this.camera.disableVideo();
      logger.info(
        'mediaShutoffThreshold reached. Turning off publisher audio and video.'
      );
      this.emit('cameraPublisherMediaDisabled');
    }

    try {
      await this.camera.publish();
      logger.debug('Published camera');
    } catch (error) {
      logger.error('Failed to join room: failed to publish to session', error);
      this._analytics.log(logAction.joinRoom, logVariation.failure, {
        error,
      });
      joinFailureCleanup();
      throw error;
    }

    this._analytics.log(logAction.joinRoom, logVariation.success);
    this._waitUntilJoined.resolver();
    this._layoutManager.updateLayout();
  };

  _cleanupOnDisconnect = () => {
    this._removeOTSessionEventListeners();
    this.camera.off('destroyed', this._onCameraPublisherDestroyed);
    this._waitUntilJoined = createExternalPromiseResolver();
    deleteAllEntries(this.participants);
    deleteAllEntries(this.participantWrappers);
  };

  leave = (): Promise<void> => {
    logger.info('Leaving room');
    this._analytics.log(logAction.leaveRoom, logVariation.attempt);
    this._cleanupOnDisconnect();
    return new Promise((resolve, reject) => {
      try {
        this._session.on('sessionDisconnected', () => {
          this._analytics.log(logAction.leaveRoom, logVariation.success);
          logger.info('Left room');
          resolve();
        });
        this._session.disconnect();
      } catch (error) {
        this._analytics.log(logAction.leaveRoom, logVariation.failure, {
          error,
        });
        logger.warn('Error leaving room', error);
        reject(error);
      }
    });
  };
  _onCameraPublisherDestroyed = () => {
    logger.error('Disconnecting room because Camera Publisher was destroyed');
    this._cleanupOnDisconnect();
    this._session.disconnect();
    this.emit('disconnected', 'cameraPublisherError');
  };

  setLayoutMode = (mode: LayoutMode) => {
    if (this._layoutManager) {
      this._layoutManager.setLayoutMode(mode);
    } else {
      logger.error(
        'Attempted to set layout mode, but are not using layout manager.'
      );
      throw new Error('Cannot set layout mode when not using layout manager');
    }
  };

  setEncryptionSecret = async (encryptionSecret: string): Promise<void> => {
    try {
      this._analytics.log(logAction.setEncryptionSecret, logVariation.attempt);
      if (this.encryptionEnabled) {
        await this._session.setEncryptionSecret(encryptionSecret);
        this._analytics.log(
          logAction.setEncryptionSecret,
          logVariation.success
        );
      } else {
        throw new Error('Must set encryption secret when creating the room');
      }
    } catch (error) {
      this._analytics.log(logAction.setEncryptionSecret, logVariation.failure, {
        error,
      });
      throw new Error(`Error changing encryption secret due to: ${error}`);
    }
  };

  startScreensharing = (targetElement?: HTMLElement | string) => {
    this._analytics.log(logAction.startScreenSharing, logVariation.attempt);
    return this.screen
      .start(targetElement)
      .then(() => {
        logger.info('Started screen sharing.');
        this._layoutManager.updateLayout();
        this._analytics.log(logAction.startScreenSharing, logVariation.success);
      })
      .catch(error => {
        logger.error('Screen sharing failed.');
        this._analytics.log(
          logAction.startScreenSharing,
          logVariation.failure,
          {
            error,
          }
        );
        throw error;
      });
  };

  stopScreensharing = () => {
    this._analytics.log(logAction.stopScreenSharing, logVariation.attempt);
    this.screen.stop();
    logger.info('Stopped screen sharing.');
    this._layoutManager.updateLayout();
    this._analytics.log(logAction.stopScreenSharing, logVariation.success);
  };

  signal({
    to,
    data,
    type,
    retryAfterReconnect,
  }: SignalOptions): Promise<void> {
    const participant = to && (this.participants[to.id] as Participant);
    const signalingOptions = removeUndefinedProperties({
      data,
      type,
      retryAfterReconnect,
      to: participant?.connection,
    });
    logger.debug('Sending signal', signalingOptions);
    return new Promise((resolve, reject) => {
      this._session.signal(signalingOptions, err => {
        if (err) {
          logger.error('Signal failed', err);
          reject(err);
        }
        resolve();
      });
    });
  }
}

export default Room;
