import { Connection } from '@opentok/client';
import { EventEmitter } from 'events';

class LocalParticipant extends EventEmitter {
  id: string;
  name?: string;
  connectionData: string;
  isMe: true = true;
  initials?: string;

  constructor(connection: Connection, name?: string) {
    super();
    this.id = connection.connectionId;
    this.name = name;
    this.connectionData = connection.data;
  }

  setInitials(initials: string) {
    this.initials = initials;
  }
}

export default LocalParticipant;
