import { createStyles } from '../../mp/styles';
import iconMicMuted from '../../assets/icons/mic-muted.svg';
import iconMicEnabled from '../../assets/icons/mic-enabled.svg';
import iconAudioMuted from '../../assets/icons/audio-muted.svg';
import iconAudioEnabled from '../../assets/icons/audio-enabled.svg';

const classes = createStyles({
  audioButton: {
    position: 'absolute',
    left: 12,
    bottom: 12,
    height: 24,
    width: 24,
    background: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(15px)',
    borderRadius: 6,
    opacity: 0,
    'transition-property': 'opacity',
    'transition-duration': '0.5s',
    'transition-timing-function': 'ease-in',
    '&:hover': {
      background: '#000000',
    },
    '&.audio-disabled': {
      background: '#E61D1D',
      opacity: 1,
    },
    '&:hover.audio-disabled': {
      background: '#9F0202',
    },
    '&.audio-enabled .icon-disabled': {
      display: 'none',
    },
    '&.audio-disabled .icon-enabled': {
      display: 'none',
    },
    '&.subscriber-audio-button': {
      display: 'none',
    },
  },
  icon: {
    height: 12,
    width: 12,
  },
});

const createSvgIcon = (svgSrc: string, ...otherClasses: Array<string>) => {
  const icon = document.createElement('img');
  icon.src = svgSrc;
  icon.classList.add(classes.icon, ...otherClasses);
  return icon;
};

const createAudioToggleButton = ({
  isAudioEnabled,
  enabledIcon,
  disabledIcon,
  type,
}: {
  isAudioEnabled: boolean;
  enabledIcon: HTMLImageElement;
  disabledIcon: HTMLImageElement;
  type: 'publisher' | 'subscriber';
}) => {
  const button = document.createElement('button');
  button.classList.add(
    classes.audioButton,
    'video-audio-button',
    type === 'publisher' ? 'publisher-audio-button' : 'subscriber-audio-button'
  );

  button.appendChild(enabledIcon);
  button.appendChild(disabledIcon);

  if (isAudioEnabled) {
    button.classList.add('audio-enabled');
  } else {
    button.classList.add('audio-disabled');
  }

  return {
    element: button,
    setEnabled() {
      button.classList.remove('audio-disabled');
      button.classList.add('audio-enabled');
    },
    setDisabled() {
      button.classList.remove('audio-enabled');
      button.classList.add('audio-disabled');
    },
  };
};

export type AudioToggleButton = {
  element: HTMLButtonElement;
  setEnabled: () => void;
  setDisabled: () => void;
};
export const createPublisherAudioToggleButton = (
  isAudioEnabled: boolean
): AudioToggleButton => {
  const disabledIcon = createSvgIcon(iconMicMuted, 'icon-disabled');
  const enabledIcon = createSvgIcon(iconMicEnabled, 'icon-enabled');
  return createAudioToggleButton({
    isAudioEnabled,
    enabledIcon,
    disabledIcon,
    type: 'publisher',
  });
};

export const createSubscriberAudioToggleButton = (
  isAudioEnabled: boolean
): AudioToggleButton => {
  const disabledIcon = createSvgIcon(iconAudioMuted, 'icon-disabled');
  const enabledIcon = createSvgIcon(iconAudioEnabled, 'icon-enabled');
  return createAudioToggleButton({
    isAudioEnabled,
    enabledIcon,
    disabledIcon,
    type: 'subscriber',
  });
};
