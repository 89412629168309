import OT from '@opentok/client';
import { Analytics, logAction, logVariation } from '../analytics';
import ConfigStore from './config-store';

// Sets up proxies for both OT and VE
const setProxyUrl = async (proxyUrl: string): Promise<void> => {
  ConfigStore.setProxyUrl(proxyUrl);

  try {
    // Makes sure that OT-specific requests and events are proxied
    OT.setProxyUrl(proxyUrl);

    // Note: we instantiate after URL is configured
    const analytics = new Analytics();

    // Yes, this `attempt` log is misleading.  We log the attempt after we
    // already did it.  We can't log before setting the proxy url, since the
    // event will leak and not go through the proxy.
    analytics.log(logAction.setProxyUrl, logVariation.attempt);
    analytics.log(logAction.setProxyUrl, logVariation.success);
  } catch (error) {
    // Note: we don't bother to generate a client event log for this
    // failture.  We just throw instead.  Reason being, we don't want any
    // logs to leak out.  Logs and requests *must* go through the proxy.
    throw new Error('There is an issue with URL provided.');
  }
};

export default setProxyUrl;
