import { EventEmitter } from 'events';
import { throttle } from 'lodash';
import Participant from '../internal/participant';
import SpeakerDetection from './speaker-detection';

type SubscriberAudioLevels = Record<string, number>;

export type ActiveSpeakerInfo = {
  subscriberId: string | undefined;
  movingAvg: number;
  participant: Participant | undefined;
};

type ParticipantBySubscriberId = {
  [key: string]: Participant;
};
type ActiveSpeakerChangedPayload = {
  previousActiveSpeaker: ActiveSpeakerInfo;
  newActiveSpeaker: ActiveSpeakerInfo;
};
type SpeakerChangeDetected = {
  isSpeaking: boolean;
};
declare interface ActiveSpeakerTracker {
  emit(
    event: 'activeSpeakerStatusChanged',
    payload: SpeakerChangeDetected
  ): boolean;
  on(
    event: 'activeSpeakerStatusChanged',
    listener: (payload: SpeakerChangeDetected) => void
  ): this;
  emit(
    event: 'activeSpeakerChanged',
    payload: ActiveSpeakerChangedPayload
  ): boolean;
  on(
    event: 'activeSpeakerChanged',
    listener: (payload: ActiveSpeakerChangedPayload) => void
  ): this;
}

const CALCULATE_ACTIVE_SPEAKER_THROTTLE_TIME = 1500;
class ActiveSpeakerTracker extends EventEmitter {
  _subscriberAudioLevelsBySubscriberId: SubscriberAudioLevels = {};
  _participantsBySubscriberId: ParticipantBySubscriberId = {};
  activeSpeaker: ActiveSpeakerInfo;
  speakerDetection: SpeakerDetection;
  calculateActiveSpeaker: () => void;

  constructor() {
    super();
    this.activeSpeaker = {
      movingAvg: 0,
      subscriberId: undefined,
      participant: undefined,
    };

    this.speakerDetection = new SpeakerDetection();

    this.speakerDetection.on('activeSpeakerStatusChanged', payload => {
      this.emit('activeSpeakerStatusChanged', payload);
    });

    this.calculateActiveSpeaker = throttle(
      this._calculateActiveSpeaker,
      CALCULATE_ACTIVE_SPEAKER_THROTTLE_TIME,
      {
        // So we don't send events after subscriber is destroyed
        leading: true,
        trailing: false,
      }
    );
  }
  onCameraSubscriberDestroyed = (subscriberId: string) => {
    delete this._subscriberAudioLevelsBySubscriberId[subscriberId];
    delete this._participantsBySubscriberId[subscriberId];
    if (this.activeSpeaker.subscriberId === subscriberId) {
      this.activeSpeaker = {
        subscriberId: undefined,
        movingAvg: 0,
        participant: undefined,
      };
    }
    this.calculateActiveSpeaker();
  };

  onSubscriberAudioLevelUpdated = ({
    subscriberId,
    movingAvg,
    participant,
    audioLevel,
  }: {
    subscriberId: string;
    movingAvg: number;
    participant: Participant;
    audioLevel: number;
  }) => {
    this._subscriberAudioLevelsBySubscriberId[subscriberId] = movingAvg;
    this._participantsBySubscriberId[subscriberId] = participant;
    this.calculateActiveSpeaker();

    if (subscriberId === this.activeSpeaker.subscriberId) {
      this.speakerDetection.onAudioLevelUpdated(audioLevel);
    }
  };

  _calculateActiveSpeaker = () => {
    const subscriberIdAudioLevelKeyValuePair = Object.entries(
      this._subscriberAudioLevelsBySubscriberId
    );
    const activeSpeaker = subscriberIdAudioLevelKeyValuePair.reduce<
      ActiveSpeakerInfo
    >(
      (acc, [subscriberId, movingAvg]) => {
        if (movingAvg > acc.movingAvg) {
          return {
            subscriberId,
            movingAvg,
            participant: this._participantsBySubscriberId[subscriberId],
          };
        }
        return acc;
      },
      {
        subscriberId: undefined,
        movingAvg: 0,
        participant: undefined,
      }
    );

    if (
      activeSpeaker.subscriberId !== this.activeSpeaker.subscriberId &&
      activeSpeaker.movingAvg > 0.2
    ) {
      const previousActiveSpeaker = { ...this.activeSpeaker };
      this.activeSpeaker = activeSpeaker;
      this.emit('activeSpeakerChanged', {
        newActiveSpeaker: activeSpeaker,
        previousActiveSpeaker,
      });
      this.speakerDetection.reset();
    }
  };
}

export default ActiveSpeakerTracker;
