import OT, { Device } from '@opentok/client';
import { EventEmitter } from 'events';
import Publisher from '../internal/publisher';
import { Analytics, logAction, logVariation } from '../analytics';
import { logger } from '../logging';
import { VideoFilter } from '../utils/types';

declare interface CameraPublisher {
  on(event: 'audioLevelUpdated', listener: (audioLevel: number) => void): this;
  on(event: 'created', listener: (reason: string) => void): this;
  on(event: 'destroyed', listener: () => void): this;
  on(event: 'accessAllowed', listener: () => void): this;
  on(event: 'accessDenied', listener: () => void): this;
  on(event: 'accessDialogOpened', listener: () => void): this;
  on(event: 'accessDialogClosed', listener: (reason: string) => void): this;
}

class CameraPublisher extends EventEmitter {
  private _defaultTargetElement: HTMLElement;
  private _cameraPub?: OT.Publisher;

  isVideoEnabled: () => boolean;
  isAudioEnabled: () => boolean;
  enableVideo: () => void;
  disableVideo: () => void;
  enableAudio: () => void;
  disableAudio: () => void;
  getVideoDevice: () => Device;
  setVideoDevice: (deviceId: string) => Promise<void>;
  getAudioDevice: () => Promise<Device>;
  setAudioDevice: (deviceId: string) => Promise<void>;
  destroyCameraPublisher: () => void;
  initPublisher: (publisherOptions?: {
    targetElement?: HTMLElement | string;
    publisherProperties?: OT.PublisherProperties;
  }) => Promise<void>;
  setVideoFilter: (videoFilter: VideoFilter) => Promise<void>;
  clearVideoFilter: () => Promise<void>;
  publish: () => Promise<void>;
  setDisabledImageURI: (imageURI: string) => void;

  constructor(
    session: OT.Session,
    defaultTargetElement: HTMLElement,
    analytics: Analytics,
    name: string,
    initials: string
  ) {
    super();
    this._defaultTargetElement = defaultTargetElement;

    const _publisher = new Publisher('camera', name, initials);

    const _attachEventsToCameraPublisher = (): void => {
      _publisher.on('audioLevelUpdated', (audioLevel: number) => {
        this.emit('audioLevelUpdated', audioLevel);
      });

      _publisher.on('videoElementCreated', () => this.emit('created'));

      _publisher.on('destroyed', () => this.emit('destroyed'));

      _publisher.on('accessAllowed', () => {
        this.emit('accessAllowed');
      });

      _publisher.on('accessDenied', () => {
        this.emit('accessDenied');
      });

      _publisher.on('accessDialogOpened', () => {
        this.emit('accessDialogOpened');
      });

      _publisher.on('accessDialogClosed', (reason: string) => {
        this.emit('accessDialogClosed', reason);
      });
    };

    this.isVideoEnabled = (): boolean => _publisher.isVideoEnabled();

    this.isAudioEnabled = (): boolean => _publisher.isAudioEnabled();

    this.enableVideo = (): void => {
      analytics.log(logAction.cameraPublisherEnableVideo, logVariation.attempt);
      _publisher.enableVideo();
      analytics.log(logAction.cameraPublisherEnableVideo, logVariation.success);
    };

    this.disableVideo = (): void => {
      analytics.log(
        logAction.cameraPublisherDisableVideo,
        logVariation.attempt
      );
      _publisher.disableVideo();
      analytics.log(
        logAction.cameraPublisherDisableVideo,
        logVariation.success
      );
    };

    this.enableAudio = (): void => {
      analytics.log(logAction.cameraPublisherEnableAudio, logVariation.attempt);
      _publisher.enableAudio();
      analytics.log(logAction.cameraPublisherEnableAudio, logVariation.success);
    };

    this.disableAudio = (): void => {
      analytics.log(
        logAction.cameraPublisherDisableAudio,
        logVariation.attempt
      );
      _publisher.disableAudio();
      analytics.log(
        logAction.cameraPublisherDisableAudio,
        logVariation.success
      );
    };

    this.getVideoDevice = (): Device => {
      analytics.log(logAction.getVideoDevice, logVariation.attempt);
      const videoDevice = _publisher.getVideoDevice();
      analytics.log(logAction.getVideoDevice, logVariation.success);
      return videoDevice;
    };

    this.setVideoDevice = async (deviceId: string): Promise<void> => {
      analytics.log(logAction.setVideoDevice, logVariation.attempt);
      try {
        await _publisher.setVideoDevice(deviceId);
        analytics.log(logAction.setVideoDevice, logVariation.success);
      } catch (error) {
        analytics.log(logAction.setVideoDevice, logVariation.failure, {
          error,
        });
        throw error;
      }
    };

    this.getAudioDevice = async (): Promise<Device> => {
      analytics.log(logAction.getAudioDevice, logVariation.attempt);
      const audioDevice = await _publisher.getAudioDevice();
      analytics.log(logAction.getAudioDevice, logVariation.success);
      return audioDevice;
    };

    this.setAudioDevice = async (deviceId: string): Promise<void> => {
      analytics.log(logAction.setAudioDevice, logVariation.attempt);
      try {
        await _publisher.setAudioDevice(deviceId);
        analytics.log(logAction.setAudioDevice, logVariation.success);
      } catch (error) {
        analytics.log(logAction.setAudioDevice, logVariation.failure, {
          error,
        });
        throw error;
      }
    };

    this.destroyCameraPublisher = (): void => {
      try {
        analytics.log(logAction.destroyCameraPublisher, logVariation.attempt);
        _publisher.destroyOTPublisher();
        analytics.log(logAction.destroyCameraPublisher, logVariation.success);
      } catch (err) {
        analytics.log(logAction.destroyCameraPublisher, logVariation.failure);
      }
    };

    this.initPublisher = async (
      publisherOptions: {
        targetElement?: HTMLElement | string;
        publisherProperties?: OT.PublisherProperties;
      } = {}
    ): Promise<void> => {
      const { targetElement, publisherProperties } = publisherOptions;
      const target = targetElement ?? this._defaultTargetElement;

      _attachEventsToCameraPublisher();

      await _publisher.initOTPublisher(target, publisherProperties);
    };

    this.publish = async (
      publisherOptions: {
        targetElement?: HTMLElement | string;
        publisherProperties?: OT.PublisherProperties;
      } = {}
    ): Promise<void> => {
      analytics.log(logAction.publishCameraPublisher, logVariation.attempt, {
        publisherOptions,
      });
      const otPublisher = await _publisher.getOTPublisher();
      if (!otPublisher) {
        throw new Error('OT Publisher not initialized');
      }

      return new Promise<void>((resolve, reject) => {
        session.publish(otPublisher, error => {
          if (error) {
            logger.error('Failed to publisher', error);
            analytics.log(
              logAction.publishCameraPublisher,
              logVariation.failure,
              {
                error,
              }
            );
            reject(error);
          } else {
            logger.info('Published camera to session');
            analytics.log(
              logAction.publishCameraPublisher,
              logVariation.success
            );
            resolve();
          }
        });
      });
    };

    this.setVideoFilter = async (videoFilter: VideoFilter) => {
      try {
        analytics.log(logAction.setVideoFilter, logVariation.attempt);
        await _publisher.setVideoFilter(videoFilter);
        analytics.log(logAction.setVideoFilter, logVariation.success);
      } catch (error) {
        analytics.log(logAction.setVideoFilter, logVariation.failure, {
          error,
        });
        throw error;
      }
    };

    this.clearVideoFilter = async () => {
      analytics.log(logAction.clearVideoFilter, logVariation.attempt);
      try {
        await _publisher.clearVideoFilter();
        analytics.log(logAction.clearVideoFilter, logVariation.success);
      } catch (error) {
        analytics.log(logAction.clearVideoFilter, logVariation.failure, {
          error,
        });
        throw error;
      }
    };

    this.setDisabledImageURI = (imageURI: string): void => {
      _publisher.setDisabledImageURI(imageURI);
    };
  }
}

export default CameraPublisher;
