import { EventEmitter } from 'events';
import {
  default as initLayout,
  Options,
  LayoutContainer,
} from 'opentok-layout-js';
import ResizeObserverPolyfill from 'resize-observer-polyfill';
import { throttle, debounce } from 'lodash';
import { LayoutMode } from '../utils/types';
import { getTargetElementFromDOM, createDiv } from '../utils/dom';
import { createStyles } from './styles';
import { logger } from '../logging';
import HiddenTileTracker from '../internal/hidden-tile-tracker';
import { removeItem } from '../utils/array';
import { classes } from './camera-subscriber';

const LARGE_CLASS_NAME = 'OT_big';
const DEFAULT_MOBILE_PARTICIPANTS = 3;
const DEFAULT_DESKTOP_PARTICIPANTS = 25;
const ACTIVE_SPEAKER_HIGHLIGHT_CLASS = 'active_speaker_highlight';
const DEFAULT_ACTIVE_SPEAKER_COLOR = '#53CA6A';

type FixedOTLayoutJSOptions = Pick<
  Options,
  'animate' | 'bigClass' | 'window' | 'ignoreClass'
>;

// Omit exposing layout options that we need to control i.e. animation and class internals
type CustomizableOTLayoutJSOptions = Omit<
  Options,
  keyof FixedOTLayoutJSOptions
>;

const defaultLayoutOptions: CustomizableOTLayoutJSOptions = {
  fixedRatio: false,
  alignItems: 'center',
  bigPercentage: 0.8,
  bigFixedRatio: false,
  bigAlignItems: 'center',
  smallAlignItems: 'center',
  maxWidth: Infinity,
  maxHeight: Infinity,
  smallMaxWidth: Infinity,
  smallMaxHeight: Infinity,
  bigMaxWidth: Infinity,
  bigMaxHeight: Infinity,
  bigMaxRatio: 3 / 2,
  bigMinRatio: 9 / 16,
  bigFirst: true,
};

const fixedLayoutOptions: FixedOTLayoutJSOptions = {
  bigClass: LARGE_CLASS_NAME,
  window,
  animate: false,
  ignoreClass: 'OT_ignore',
};

const getClasses = (speakerHighlightColor?: string) => {
  const outerMargin = 8;
  const marginOffset = 2 * outerMargin; // i.e. marginTop + marginBottom or marginLeft + marginRight
  return createStyles({
    layoutContainer: {
      margin: outerMargin,
      width: `calc(100% - ${marginOffset}px)`,
      height: `calc(100% - ${marginOffset}px)`,
      position: 'relative',
      '& > *': {
        width: 0,
        height: 0,
        opacity: 0,
      },
      '& > .ot-layout': {
        opacity: 1,
      },
      '& > .OT_publisher, & > .OT_subscriber': {
        margin: 4,
        borderRadius: 16,
        // Fix for safari border radius https://stackoverflow.com/a/16681137/5433407
        '-webkit-backface-visibility': 'hidden',
        '-webkit-transform': 'translate3d(0, 0, 0)',
      },
      '& > .OT_subscriber.MP_screenshare_subscriber': {
        borderRadius: 0,
      },
      [`& > .OT_subscriber.${ACTIVE_SPEAKER_HIGHLIGHT_CLASS}`]: {
        borderColor: `${speakerHighlightColor || DEFAULT_ACTIVE_SPEAKER_COLOR}`,
        borderWidth: `3px`,
        borderStyle: `solid`,
      },
    },
    layoutContainerWrapper: {
      width: '100%',
      height: '100%',
      overflow: 'auto',
    },
  });
};

export type LayoutOptions = {
  layoutMode?: LayoutMode;
  speakerHighlightEnabled?: boolean;
  speakerHighlightColor?: string;
  cameraPublisherContainer?: HTMLElement | string;
  screenPublisherContainer?: HTMLElement | string;
  isMobileLayout: boolean;
};

declare interface LayoutManager {
  on(event: 'layoutComplete', listener: () => void): this;
  on(
    event: 'cameraSubscriberHidden',
    listener: (subscriberId: string) => void
  ): this;
  on(
    event: 'cameraSubscriberDisplayed',
    listener: (subscriberId: string) => void
  ): this;
}

class LayoutManager extends EventEmitter {
  _layoutContainer: LayoutContainer;
  _layoutContainerElement: HTMLElement;
  _activeSpeakerElement?: HTMLElement;
  _screenSharingElementIds: Array<string> = [];
  _cameraSubscriberElementIds: Array<string> = [];
  _layoutMode: LayoutMode = 'grid';
  isViewingScreenshare: boolean = false;
  _emitLayoutRecalculated: () => void;
  _isMobileLayout: boolean;
  _hiddenTileTracker: HiddenTileTracker;
  _speakerHighlightColor?: string;
  _speakerHighlightEnabled?: boolean;

  constructor(
    targetElement: HTMLElement | string,
    layoutOptions: LayoutOptions,
    maxVideoParticipantsOnScreen?: Number
  ) {
    super();
    const { layoutMode = 'grid', ...customOTLayoutJsOptions } = layoutOptions;
    this._layoutMode = layoutMode;
    this._isMobileLayout = layoutOptions.isMobileLayout;

    this._speakerHighlightColor = layoutOptions.speakerHighlightColor;
    this._speakerHighlightEnabled = layoutOptions.speakerHighlightEnabled;

    if (this._speakerHighlightColor && !this._speakerHighlightEnabled) {
      logger.warn(
        'You passed speakerHighlightColor value without setting speakerHighlightEnabled'
      );
    }

    const maxRatio = this._isMobileLayout ? 16 / 9 : 14 / 16;
    const minRatio = this._isMobileLayout ? 3 / 2 : 9 / 16;

    defaultLayoutOptions.maxRatio = maxRatio;
    defaultLayoutOptions.minRatio = minRatio;

    this._layoutContainerElement = this._initLayoutContainerElement(
      targetElement
    );
    this._emitLayoutRecalculated = debounce(() => {
      this.emit('layoutComplete');
    }, 20); // wait for CSS transition to finish before sending event. If no transition (0) then add small delay anyway

    this._layoutContainer = initLayout(this._layoutContainerElement, {
      ...defaultLayoutOptions,
      ...customOTLayoutJsOptions, // Overwrite default layout options with users custom options
      ...fixedLayoutOptions, // Fixed layout options last to ensure not overwritten by user
    });

    this._initContainerResizeListener();

    // Max onscreen video participants: default or user-specified parameter depending on mobile/desktop layout
    if (this._isMobileLayout) {
      this._hiddenTileTracker = new HiddenTileTracker(
        DEFAULT_MOBILE_PARTICIPANTS
      );
    } else {
      this._hiddenTileTracker = new HiddenTileTracker(
        maxVideoParticipantsOnScreen || DEFAULT_DESKTOP_PARTICIPANTS
      );
    }
  }

  _initLayoutContainerElement = (
    roomContainerElement: HTMLElement | string
  ) => {
    let roomContainer: HTMLElement = getTargetElementFromDOM(
      roomContainerElement
    );

    const layoutContainerElement = createDiv('layoutContainer');
    const layoutContainerWrapperElement = createDiv('layoutContainerWrapper');

    const classes = getClasses(this._speakerHighlightColor);
    layoutContainerElement.classList.add(classes.layoutContainer);
    layoutContainerWrapperElement.classList.add(classes.layoutContainerWrapper);

    layoutContainerWrapperElement.appendChild(layoutContainerElement);
    roomContainer.appendChild(layoutContainerWrapperElement);

    return layoutContainerElement;
  };

  _initContainerResizeListener = () => {
    const throttledRecalculateLayout = throttle(
      () => this._recalculateTileSizes(),
      20
    );
    const resizeObserver = new ResizeObserverPolyfill(() => {
      throttledRecalculateLayout();
    });
    resizeObserver.observe(this._layoutContainerElement);
  };

  setLayoutMode = (layoutMode: LayoutMode) => {
    this._layoutMode = layoutMode;
    this.updateLayout();
  };

  _setElementBig = (element: HTMLElement) => {
    element.classList.add(LARGE_CLASS_NAME);
  };

  _setElementSmall = (element: HTMLElement) => {
    element.classList.remove(LARGE_CLASS_NAME);
  };

  _hideElement = (element: HTMLElement) => {
    const currentStyle = getComputedStyle(element);
    if (currentStyle.display !== 'none') {
      element.style.display = 'none';
      if (this._cameraSubscriberElementIds.includes(element.id)) {
        this.emit('cameraSubscriberHidden', element.id);
      }
    }
  };

  _displayElement = (element: HTMLElement) => {
    const currentStyle = getComputedStyle(element);
    if (currentStyle.display !== 'block') {
      element.style.display = 'block';
      if (this._cameraSubscriberElementIds.includes(element.id)) {
        this.emit('cameraSubscriberDisplayed', element.id);
      }
    }
  };

  _removeSubscriberOnLoad = (element: HTMLElement) => {
    element.classList.remove(classes.subscriberOnLoad);
  };

  _setAllElementsSmall = () => {
    this._layoutContainerElement.childNodes.forEach(node => {
      if (node instanceof HTMLElement) {
        this._setElementSmall(node);
      }
    });
  };

  _setOnlyScreenElementsBig = () => {
    this._layoutContainerElement.childNodes.forEach(node => {
      if (!(node instanceof HTMLElement)) {
        return;
      }
      if (!this._screenSharingElementIds.includes(node.id)) {
        this._setElementSmall(node);
      } else {
        this._setElementBig(node);
      }
    });
  };

  _setOnlyActiveSpeakerElementBig = () => {
    const elementToSetBig = this._getActiveSpeakerViewLargeElement();
    this._setAllElementsSmall();

    if (elementToSetBig) {
      this._setElementBig(elementToSetBig);
    }
  };

  _getActiveSpeakerViewLargeElement = (): HTMLElement | undefined => {
    if (this._activeSpeakerElement) {
      return this._activeSpeakerElement;
    }
    const subscriber = this._layoutContainerElement.querySelector(
      '.OT_subscriber'
    );
    if (subscriber) {
      return subscriber as HTMLElement;
    }
    const publisher = this._layoutContainerElement.querySelector(
      '.OT_publisher'
    );
    if (publisher) {
      return publisher as HTMLElement;
    }
    logger.error(
      'No publisher or subscriber elements found when setting active speaker layout'
    );
    return;
  };

  _displayOnlyScreenElement = () => {
    this._layoutContainerElement.childNodes.forEach(node => {
      if (!(node instanceof HTMLElement)) {
        return;
      }
      if (!this._screenSharingElementIds.includes(node.id)) {
        this._hideElement(node);
      } else {
        this._setElementBig(node);
      }
    });
  };

  _displayOnlyActiveSpeaker = () => {
    const elementToSetBig = this._getActiveSpeakerViewLargeElement();
    this._layoutContainerElement.childNodes.forEach(node => {
      if (!(node instanceof HTMLElement)) {
        return;
      } else if (node !== elementToSetBig) {
        this._hideElement(node);
      } else {
        this._displayElement(node);
      }
    });
  };

  _displayLimitedGridElements = () => {
    this._layoutContainerElement.childNodes.forEach(node => {
      if (!(node instanceof HTMLElement)) {
        return;
      } else if (this._hiddenTileTracker?.hiddenElements.includes(node)) {
        this._hideElement(node);
      } else {
        this._displayElement(node);
      }
    });
  };

  onActiveSpeakerChanged({
    newActiveSpeakerElement,
    previousActiveSpeakerElement,
  }: {
    newActiveSpeakerElement?: HTMLElement;
    previousActiveSpeakerElement?: HTMLElement;
  }) {
    this._activeSpeakerElement = newActiveSpeakerElement;

    // new active speaker detected, highight them and remove the previous one
    if (this._speakerHighlightEnabled) {
      if (previousActiveSpeakerElement) {
        previousActiveSpeakerElement.classList.remove(
          ACTIVE_SPEAKER_HIGHLIGHT_CLASS
        );
      }

      if (newActiveSpeakerElement) {
        newActiveSpeakerElement.classList.add(ACTIVE_SPEAKER_HIGHLIGHT_CLASS);
      }
    }

    this._hiddenTileTracker?.onActiveSpeakerChanged(newActiveSpeakerElement);
    this.updateLayout();
  }

  onActiveSpeakerStatusChanged({ isSpeaking }: { isSpeaking: boolean }) {
    if (this._speakerHighlightEnabled) {
      const addOrRemoveClass = isSpeaking ? 'add' : 'remove';
      this._activeSpeakerElement?.classList[addOrRemoveClass](
        ACTIVE_SPEAKER_HIGHLIGHT_CLASS
      );
    }
  }

  _recalculateTileSizes() {
    this._layoutContainer.layout();
    this._emitLayoutRecalculated();
  }

  _isSubscribingToScreen = () => this._screenSharingElementIds.length > 0;

  onScreenSubscriberCreated = (subscriberId?: string) => {
    if (subscriberId) {
      this._screenSharingElementIds.push(subscriberId);
      this.isViewingScreenshare = true;
    }
    this.updateLayout();
  };

  onScreenSubscriberDestroyed = (subscriberId?: string) => {
    if (subscriberId) {
      removeItem(this._screenSharingElementIds, subscriberId);
    }
    if (this._screenSharingElementIds.length === 0) {
      this.isViewingScreenshare = false;
    }
    this.updateLayout();
  };

  onCameraSubscriberCreated = (element: HTMLElement | undefined) => {
    if (element) {
      this._hiddenTileTracker?.onSubscriberCreated(element);
      this._cameraSubscriberElementIds.push(element.id);
      this.emit('cameraSubscriberDisplayed', element.id);
    }
    this.updateLayout();
  };

  onCameraSubscriberDestroyed = (element: HTMLElement | undefined) => {
    if (element) {
      this._hiddenTileTracker?.onSubscriberRemoved(element);
      removeItem(this._cameraSubscriberElementIds, element.id);
    }
    this.updateLayout();
  };

  getLayoutContainerElement() {
    return this._layoutContainerElement;
  }

  updateLayout() {
    if (this._isMobileLayout) {
      if (this.isViewingScreenshare) {
        this._displayOnlyScreenElement();
      } else if (this._layoutMode === 'active-speaker') {
        this._displayOnlyActiveSpeaker();
      } else {
        this._displayLimitedGridElements();
      }
    } else {
      if (this.isViewingScreenshare) {
        this._setOnlyScreenElementsBig();
      } else if (this._layoutMode === 'active-speaker') {
        this._setOnlyActiveSpeakerElementBig();
      } else {
        this._setAllElementsSmall();
      }
      this._displayLimitedGridElements();
    }
    this._recalculateTileSizes();
  }
}

export default LayoutManager;
