import OT from '@opentok/client';
import { EventEmitter } from 'events';
import Room from '../../internal/room';
import {
  LayoutMode,
  ManagedLayoutOptions,
  RoomProperties,
  IceConfig,
} from '../../utils/types';
import Participant from '../../internal/participant';
import CameraPublisher from '../camera-publisher';
import ScreenPublisher from '../screen-publisher';
import ParticipantWrapper from '../participant';
import { SignalOptions, SignalEvent } from '../../types/signaling';
import LocalParticipantWrapper from '../local-participant';

declare interface RoomWrapper {
  on(event: 'connected', listener: () => void): this; // session connected event
  on(event: 'disconnected', listener: (reason: string) => void): this; // session disconnected event
  on(event: 'reconnecting', listener: () => void): this; // session reconnecting event
  on(event: 'reconnected', listener: () => void): this; // session reconnected event
  on(event: 'cameraPublisherMediaDisabled', listener: () => void): this;
  on(
    event: 'cameraSubscriberVideoDisabled',
    listener: (id: string) => void
  ): this;
  on(
    event: 'participantJoined',
    listener: (participant: Participant) => void
  ): this;
  on(
    event: 'participantLeft',
    listener: (participant: Participant, reason: string) => void
  ): this;
  on(
    event: 'activeSpeakerChanged',
    listener: (participant: Participant) => void
  ): this;
  on(event: 'signal', listener: (event: SignalEvent) => void): this;
}

class RoomWrapper extends EventEmitter {
  apiKey: string;
  roomId: string;
  token: string;
  iceConfig?: IceConfig;
  roomContainer?: HTMLElement | string;
  managedLayoutOptions?: ManagedLayoutOptions;
  mediaShutoffThreshold?: number;
  maxVideoParticipantsOnScreen?: number;
  camera: CameraPublisher;
  screen: ScreenPublisher;
  participants: Record<string, ParticipantWrapper | LocalParticipantWrapper>;
  participantId?: string;
  participantName?: string;
  analytics: any;
  participantConnectionData?: string;
  participantInitials?: string;

  join: (publisherOptions?: {
    targetElement?: HTMLElement | string;
    publisherProperties?: OT.PublisherProperties;
  }) => Promise<void>;
  leave: () => Promise<void>;
  setLayoutMode: (mode: LayoutMode) => void;
  startScreensharing: (targetElement?: HTMLElement | string) => Promise<void>;
  stopScreensharing: () => void;
  setEncryptionSecret: (encryptionSecret: string) => Promise<void>;
  signal: (payload: SignalOptions) => Promise<void>;

  constructor(props: RoomProperties) {
    super();

    this.apiKey = props.apiKey;
    this.roomId = props.sessionId;
    this.token = props.token;
    this.iceConfig = props.iceConfig;
    this.roomContainer = props.roomContainer;
    this.managedLayoutOptions = props.managedLayoutOptions;
    this.mediaShutoffThreshold = props.mediaShutoffThreshold;
    this.maxVideoParticipantsOnScreen = props.maxVideoParticipantsOnScreen;

    const room = new Room(props);

    this.camera = room.camera;
    this.screen = room.screen;
    this.participants = room.participantWrappers;
    Object.defineProperty(this, 'participantId', {
      get: () => room.participantId,
    });
    Object.defineProperty(this, 'participantName', {
      get: () => room.participantName,
    });
    Object.defineProperty(this, 'participantConnectionData', {
      get: () => room.participantConnectionData,
    });
    Object.defineProperty(this, 'participantInitials', {
      get: () => room.participantInitials,
    });

    const _attachEventsToRoomWrapper = async (): Promise<void> => {
      room.on('connected', () => {
        this.emit('connected');
      });

      room.on('disconnected', (reason: string) =>
        this.emit('disconnected', reason)
      );

      room.on('reconnecting', () => {
        this.emit('reconnecting');
      });

      room.on('reconnected', () => {
        this.emit('reconnected');
      });

      room.on('cameraPublisherMediaDisabled', () => {
        this.emit('cameraPublisherMediaDisabled');
      });

      room.on('cameraSubscriberVideoDisabled', (id: string) => {
        this.emit('cameraSubscriberVideoDisabled', id);
      });

      room.on('participantJoined', (participant: ParticipantWrapper) => {
        this.emit('participantJoined', participant);
      });

      room.on(
        'participantLeft',
        (participant: ParticipantWrapper, reason: string) => {
          this.emit('participantLeft', participant, reason);
        }
      );

      room.on('activeSpeakerChanged', (participant: ParticipantWrapper) => {
        this.emit('activeSpeakerChanged', participant);
      });

      room.on('signal', (event: SignalEvent) => {
        // type comes as 'signal:type' or just 'signal'
        const strippedType = event.type?.split('signal:')?.[1] ?? '';
        this.emit('signal', event);
        if (strippedType) {
          this.emit(`signal:${strippedType}`, event);
        }
      });
    };

    _attachEventsToRoomWrapper();

    this.join = (
      publisherOptions: {
        targetElement?: HTMLElement | string;
        publisherProperties?: OT.PublisherProperties;
      } = {}
    ): Promise<void> => room.join(publisherOptions);

    this.leave = () => room.leave();

    this.setLayoutMode = (mode: LayoutMode) => room.setLayoutMode(mode);

    this.startScreensharing = (targetElement?: HTMLElement | string) =>
      room.startScreensharing(targetElement);

    this.stopScreensharing = () => room.stopScreensharing();

    this.setEncryptionSecret = (encryptionSecret: string) =>
      room.setEncryptionSecret(encryptionSecret);

    this.signal = (payload: SignalOptions) => room.signal(payload);
  }
}

export default RoomWrapper;
