import { createStyles } from '../../mp/styles';
import iconNetworkQualityPoor from '../../assets/icons/red-wifi.svg';

const classes = createStyles({
  networkQualityWarning: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '24px',
    minWidth: '24px',
    background: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(15px)',
    borderRadius: '6px',
    opacity: 0,
  },
  networkQualityIcon: {
    width: '20px',
    height: '20px',
  },
  active: {
    opacity: 1,
  },
});

type NetworkQualityWarning = {
  element: HTMLElement;
  show: () => void;
  hide: () => void;
};

export const createNetworkQualityWarning = (): NetworkQualityWarning => {
  const div = document.createElement('div');
  div.classList.add(classes.networkQualityWarning, 'network-quality-warning');
  const icon = document.createElement('img');
  icon.src = iconNetworkQualityPoor;
  icon.classList.add(
    classes.networkQualityIcon,
    'network-quality-warning-icon'
  );
  div.appendChild(icon);
  return {
    element: div,
    show: () => div.classList.add(classes.active),
    hide: () => div.classList.remove(classes.active),
  };
};
