import jss, { JssStyle } from 'jss';
import preset from 'jss-preset-default';
import camelCase from 'jss-plugin-camel-case';

// One time setup with default plugins and settings.
jss.setup(preset());
jss.use(camelCase());

export const createStyles = (styles: Record<string, JssStyle>) => {
  const { classes } = jss.createStyleSheet(styles).attach();
  return classes;
};
