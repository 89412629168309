import { EventEmitter } from 'events';
import { logger } from '../../logging';
import { createDiv } from '../../utils/dom';
import { createStyles } from '../../mp/styles';
import {
  createPublisherAudioToggleButton,
  createSubscriberAudioToggleButton,
} from './audio-button';
import { createNameDisplay } from './display-name';
import { createNetworkQualityWarning } from './network-quality-warning';

const classes = createStyles({
  videoOverlay: {
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: 1, // opentok overlay has z-index 1.
    '&:hover .video-audio-button': {
      opacity: 1,
    },
  },
  bottomRightContainer: {
    maxWidth: 'calc(100% - 68px)',
    position: 'absolute',
    bottom: 12,
    top: 'auto',
    right: 12,
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
});

export type VideoOverlay = {
  on(event: 'toggleAudioClicked', listener: () => void): void;
  emit(event: 'toggleAudioClicked'): void;
  setAudioEnabled: () => void;
  setAudioDisabled: () => void;
  showNetworkQualityWarning: () => void;
  hideNetworkQualityWarning: () => void;
};

const createOverlay = ({
  targetId,
  participantName,
  isAudioEnabled,
  type,
}: {
  targetId: string;
  participantName?: string;
  isAudioEnabled: boolean;
  type: 'publisher' | 'subscriber';
}): VideoOverlay | undefined => {
  const targetVideoContainerDiv = document.getElementById(targetId);
  if (!targetVideoContainerDiv) {
    logger.warn('Target div not found when creating overlay: ', targetId);
    return;
  }

  let overlayClass;
  let overlayId;
  let audioToggleButton;
  if (type === 'publisher') {
    overlayClass = 'MP_publisher_overlay';
    overlayId = 'MP_camera_publisher_default_controls';
    audioToggleButton = createPublisherAudioToggleButton(isAudioEnabled);
  } else {
    overlayClass = 'MP_subscriber_overlay';
    overlayId = `MP_${targetId}_default_controls`;
    audioToggleButton = createSubscriberAudioToggleButton(isAudioEnabled);
  }

  const overlayDiv = createDiv(overlayId);
  overlayDiv.classList.add(classes.videoOverlay, overlayClass);

  overlayDiv.appendChild(audioToggleButton.element);

  const bottomRightContainer = document.createElement('div');
  bottomRightContainer.classList.add(classes.bottomRightContainer);
  const networkQualityWarning = createNetworkQualityWarning();
  bottomRightContainer.appendChild(networkQualityWarning.element);
  if (participantName) {
    const nameElement = createNameDisplay(participantName);
    bottomRightContainer.appendChild(nameElement);
  }
  overlayDiv.appendChild(bottomRightContainer);

  targetVideoContainerDiv.appendChild(overlayDiv);

  const overlay = Object.assign(new EventEmitter(), {
    setAudioEnabled: audioToggleButton.setEnabled,
    setAudioDisabled: audioToggleButton.setDisabled,
    showNetworkQualityWarning: networkQualityWarning.show,
    hideNetworkQualityWarning: networkQualityWarning.hide,
  });

  audioToggleButton.element.addEventListener('click', () => {
    overlay.emit('toggleAudioClicked');
  });

  return overlay;
};

export const createPublisherOverlay = ({
  id,
  isAudioEnabled,
  participantName,
}: {
  id: string;
  isAudioEnabled: boolean;
  participantName?: string;
}): VideoOverlay | undefined =>
  createOverlay({
    targetId: id,
    isAudioEnabled,
    participantName,
    type: 'publisher',
  });

export const createSubscriberOverlay = ({
  id,
  participantName,
}: {
  id: string;
  participantName: string;
}): VideoOverlay | undefined =>
  createOverlay({
    targetId: id,
    isAudioEnabled: true,
    participantName,
    type: 'subscriber',
  });
