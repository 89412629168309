let proxyUrl: string;

const ConfigStore = {
  setProxyUrl: (url: string) => {
    proxyUrl = url;
  },
  getProxyUrl: () => proxyUrl,
};

export default ConfigStore;
