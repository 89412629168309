import {
  Stream,
  Subscriber as OTSubscriber,
  Session,
  OTError,
} from '@opentok/client';
import { EventEmitter } from 'events';
import Subscriber from '../internal/subscriber';
import { Analytics, logAction, logVariation } from '../analytics';
import { logger } from '../logging';
import iconSmileyFace from '../assets/icons/smile';
import { createStyles } from '../mp/styles';

export const classes = createStyles({
  subscriberOnLoad: {
    display: ['none', '!important'],
  },
});

declare interface CameraSubscriber {
  on(
    event: 'audioLevelUpdated',
    listener: (audioLevel: number, movingAvg: number) => void
  ): this;
  on(event: 'created', listener: () => void): this;
  on(event: 'destroyed', listener: () => void): this;
}

class CameraSubscriber extends EventEmitter {
  // @ts-ignore @TODO: Verify whether _stream variable required for this class?
  private _stream: Stream;
  private movingAvg: number | null;
  id: string;
  setOptimalPreferredResolution: () => void;
  getSubscriberElement: () => HTMLElement | undefined;
  isVideoEnabled: () => boolean;
  isAudioEnabled: () => boolean;
  enableVideo: () => void;
  disableVideo: () => void;
  enableAudio: () => void;
  disableAudio: () => void;
  setDisabledImageURI: (imageURI: string) => void;

  constructor(
    stream: Stream,
    otSubscriber: OTSubscriber,
    analytics: Analytics
  ) {
    super();
    this._stream = stream;
    this.movingAvg = null;
    const _subscriber = new Subscriber(otSubscriber);
    this.id = _subscriber.id as string;

    _subscriber.on('audioLevelUpdated', (audioLevel: number) => {
      if (this.movingAvg === null || this.movingAvg <= audioLevel) {
        this.movingAvg = audioLevel;
      } else {
        this.movingAvg = 0.7 * this.movingAvg + 0.3 * audioLevel;
      }
      // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
      let logLevel = Math.log(this.movingAvg) / Math.LN10 / 1.5 + 1;
      logLevel = Math.min(Math.max(logLevel, 0), 1);
      this.emit('audioLevelUpdated', audioLevel, logLevel);
    });

    _subscriber.on('destroyed', () => {
      this.emit('destroyed');
    });

    _subscriber.on('videoElementCreated', () => {
      this.emit('created');
    });

    this.setOptimalPreferredResolution = () => {
      if (_subscriber) {
        const { otSubscriber } = _subscriber;
        const videoElementWidth = otSubscriber.element?.offsetWidth;
        const videoElementHeight = otSubscriber.element?.offsetHeight;
        if (videoElementWidth && videoElementHeight) {
          const preferredResolution = {
            width: videoElementWidth * window.devicePixelRatio,
            height: videoElementHeight * window.devicePixelRatio,
          };
          otSubscriber.setPreferredResolution(preferredResolution);
        }
      }
    };

    this.getSubscriberElement = () => _subscriber?.getSubscriberElement();

    this.isVideoEnabled = () => _subscriber?.isVideoEnabled();

    this.isAudioEnabled = () => _subscriber?.isAudioEnabled();

    this.enableVideo = () => {
      analytics.log(
        logAction.cameraSubscriberEnableVideo,
        logVariation.attempt
      );
      if (_subscriber) {
        _subscriber.enableVideo();
        logger.debug('Enabled subscriber video: ', this.id);
        analytics.log(
          logAction.cameraSubscriberEnableVideo,
          logVariation.success
        );
      } else {
        analytics.log(
          logAction.cameraSubscriberEnableVideo,
          logVariation.failure
        );
      }
    };

    this.disableVideo = () => {
      analytics.log(
        logAction.cameraSubscriberDisableVideo,
        logVariation.attempt
      );
      if (_subscriber) {
        _subscriber.disableVideo();
        logger.debug('Disabled subscriber video: ', this.id);
        analytics.log(
          logAction.cameraSubscriberDisableVideo,
          logVariation.success
        );
      } else {
        analytics.log(
          logAction.cameraSubscriberDisableVideo,
          logVariation.failure
        );
      }
    };

    this.enableAudio = () => {
      analytics.log(
        logAction.cameraSubscriberEnableAudio,
        logVariation.attempt
      );
      if (_subscriber) {
        _subscriber.enableAudio();
        analytics.log(
          logAction.cameraSubscriberEnableAudio,
          logVariation.success
        );
      } else {
        analytics.log(
          logAction.cameraSubscriberEnableAudio,
          logVariation.failure
        );
      }
    };

    this.disableAudio = () => {
      analytics.log(
        logAction.cameraSubscriberDisableAudio,
        logVariation.attempt
      );
      if (_subscriber) {
        _subscriber.disableAudio();
        analytics.log(
          logAction.cameraSubscriberDisableAudio,
          logVariation.success
        );
      } else {
        analytics.log(
          logAction.cameraSubscriberDisableAudio,
          logVariation.failure
        );
      }
    };

    this.setDisabledImageURI = (imageURI: string): void => {
      _subscriber.setDisabledImageURI(imageURI);
    };
  }
}

export default CameraSubscriber;

/* We can't use a promise here because the videoElementCreated event may be fired between
 * calling resolve() and the success handler being executed. Instead we use a synchronous callback.
 */
export const createCameraSubscriber = (
  session: Session,
  stream: Stream,
  shouldEnableVideo: boolean,
  analytics: Analytics,
  callback: (error?: OTError, cameraSubscriber?: CameraSubscriber) => void
) => {
  const getBackgroundImageURI = () => (stream.initials ? '' : iconSmileyFace);
  const _otSubscriber = session.subscribe(
    stream,
    'layoutContainer',
    {
      insertMode: 'append',
      subscribeToVideo: shouldEnableVideo,
      style: {
        buttonDisplayMode: 'off',
        nameDisplayMode: 'off',
        videoDisabledDisplayMode: 'off',
        backgroundImageURI: getBackgroundImageURI(),
      },
    },
    error => {
      analytics.log(logAction.createCameraSubscriber, logVariation.attempt);
      if (error) {
        analytics.log(logAction.createCameraSubscriber, logVariation.failure, {
          error,
        });
        callback(error);
      } else {
        const cameraSubscriber = new CameraSubscriber(
          stream,
          _otSubscriber,
          analytics
        );
        callback(undefined, cameraSubscriber);
        analytics.log(logAction.createCameraSubscriber, logVariation.success);
      }
    }
  );
  if (_otSubscriber.element instanceof HTMLElement) {
    // hide loading subscriber videos until ready to be displayed by layout manager
    _otSubscriber.element?.classList.add(classes.subscriberOnLoad);
  }
};
