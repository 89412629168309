export const removeUndefinedProperties = (
  properties: Record<string, any>
): Record<string, string> => {
  return Object.entries(properties).reduce((acc, [propName, value]) => {
    if (value !== undefined) {
      acc[propName] = value;
    }
    return acc;
  }, {} as Record<string, any>);
};

export const deleteAllEntries = (object: Record<string, any>) => {
  Object.keys(object).forEach(key => {
    delete object[key];
  });
};
