import { createStyles } from '../../mp/styles';

const classes = createStyles({
  name: {
    background: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(15px)',
    borderRadius: '6px',
    paddingLeft: '8px',
    paddingRight: '8px',
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '24px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '80%',
    marginLeft: '12px',
  },
});
export const createNameDisplay = (name: string) => {
  const div = document.createElement('div');
  div.innerText = name;
  div.classList.add(classes.name, 'participant-name');
  return div;
};
