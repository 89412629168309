import getDevices from './get-devices';

const getDeviceIdFromSettings = (track: MediaStreamTrack): string | undefined =>
  track.getSettings ? track.getSettings().deviceId : '';

const getDeviceIdFromDevicesList = async (
  track: MediaStreamTrack
): Promise<string | null> => {
  let devices;
  let deviceId = null;
  try {
    devices = await getDevices();
    if (devices && devices.length) {
      const currentDevice = devices.find(
        device => device.label === track.label
      );
      if (currentDevice) {
        deviceId = currentDevice.deviceId;
      }
    }
  } catch (e) {
    // Don't need to throw. Instead, we'll just return null;
  }
  return deviceId;
};

const getDeviceId = async (track: MediaStreamTrack): Promise<string> =>
  getDeviceIdFromSettings(track) ||
  (await getDeviceIdFromDevicesList(track)) ||
  '';

export default getDeviceId;
