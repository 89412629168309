import OT from '@opentok/client';
import { EventEmitter } from 'events';
import { throttle } from 'lodash';
import { logger } from '../logging';
import { createSubscriberOverlay, VideoOverlay } from './video-overlay/overlay';

declare interface Subscriber {
  on(event: 'audioLevelUpdated', listener: (audioLevel: number) => void): this;
  on(event: 'videoElementCreated', listener: () => void): this;
  on(event: 'destroyed', listener: () => void): this;
}

class Subscriber extends EventEmitter {
  otSubscriber: OT.Subscriber;
  id: string | undefined;
  overlay: VideoOverlay | undefined;
  _subscribingToAudio: boolean;

  constructor(otSubscriber: OT.Subscriber) {
    super();
    this.otSubscriber = otSubscriber;
    this.id = otSubscriber.id;
    this._subscribingToAudio = true;

    const _onAudioLevelUpdated = (event: { audioLevel: number }) => {
      this.emit('audioLevelUpdated', event.audioLevel);
    };

    const audioLevelUpdatedHandler = throttle(_onAudioLevelUpdated, 60, {
      // So we don't send events after stream is destroyed
      leading: true,
      trailing: false,
    });

    this.otSubscriber.on('audioLevelUpdated', audioLevelUpdatedHandler);
    this.otSubscriber.on('destroyed', () => this.emit('destroyed'));
    this.otSubscriber.on('videoElementCreated', () => {
      if (this.otSubscriber?.stream?.videoType !== 'screen') {
        if (this.id) {
          this.overlay = createSubscriberOverlay({
            id: this.id,
            participantName: this.otSubscriber.stream?.name ?? '',
          });
          this.overlay?.on('toggleAudioClicked', () => {
            this._toggleAudioEnabled();
          });
        } else {
          logger.warn('Subscriber id undefined when trying to create overlay');
        }
      }
      this.emit('videoElementCreated');
    });
    this.otSubscriber.on('videoDisableWarning', () =>
      this.overlay?.showNetworkQualityWarning()
    );
    this.otSubscriber.on('videoDisableWarningLifted', () =>
      this.overlay?.hideNetworkQualityWarning()
    );
    this.otSubscriber.on('videoDisabled', ({ reason }) => {
      if (reason === 'quality') {
        this.overlay?.showNetworkQualityWarning();
      }
    });
    this.otSubscriber.on('videoEnabled', ({ reason }) => {
      if (reason === 'quality') {
        this.overlay?.hideNetworkQualityWarning();
      }
    });
  }
  _toggleAudioEnabled = () => {
    if (this._subscribingToAudio) {
      this.disableAudio();
    } else {
      this.enableAudio();
    }
  };

  getSubscriberElement = () => this.otSubscriber?.element;

  isVideoEnabled = (): boolean => !!this.otSubscriber?.stream?.hasVideo;

  isAudioEnabled = (): boolean => !!this.otSubscriber?.stream?.hasAudio;

  _setSubscribeToVideo = (shouldSubscribe: boolean): void =>
    this.otSubscriber?.subscribeToVideo(shouldSubscribe);
  enableVideo = (): void => {
    this.otSubscriber?.subscribeToVideo(true);
  };

  disableVideo = (): void => this.otSubscriber?.subscribeToVideo(false);

  enableAudio = (): void => {
    this.otSubscriber?.subscribeToAudio(true);
    this._subscribingToAudio = true;
    this.overlay?.setAudioEnabled();
  };

  disableAudio = (): void => {
    this.otSubscriber?.subscribeToAudio(false);
    this._subscribingToAudio = false;
    this.overlay?.setAudioDisabled();
  };

  setDisabledImageURI = (imageURI: string): void => {
    this.otSubscriber?.setStyle('backgroundImageURI', imageURI);
  };
}

export default Subscriber;
