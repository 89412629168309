import OTKAnalytics from 'opentok-solutions-logging';
import ConfigStore from './mp/config-store';

/** Analytics */

const logVariation = {
  attempt: 'Attempt',
  success: 'Success',
  failure: 'Failure',
} as const;

const logAction = {
  // vars for the analytics logs. Internal use
  initRoom: 'InitRoom',
  joinRoom: 'JoinRoom',
  leaveRoom: 'LeaveRoom',
  startScreenSharing: 'StartScreenSharing',
  stopScreenSharing: 'StopScreenSharing',
  publishCameraPublisher: 'PublishCameraPublisher',
  destroyCameraPublisher: 'DestroyCameraPublisher',
  cameraPublisherEnableVideo: 'CameraPublisherEnableVideo',
  cameraPublisherEnableAudio: 'CameraPublisherEnableAudio',
  cameraPublisherDisableVideo: 'CameraPublisherDisableVideo',
  cameraPublisherDisableAudio: 'CameraPublisherDisableAudio',
  screenPublisherEnableVideo: 'ScreenPublisherEnableVideo',
  screenPublisherEnableAudio: 'ScreenPublisherEnableAudio',
  screenPublisherDisableVideo: 'ScreenPublisherDisableVideo',
  screenPublisherDisableAudio: 'ScreenPublisherDisableAudio',
  cameraSubscriberEnableVideo: 'CameraSubscriberEnableVideo',
  cameraSubscriberEnableAudio: 'CameraSubscriberEnableAudio',
  cameraSubscriberDisableVideo: 'CameraSubscriberDisableVideo',
  cameraSubscriberDisableAudio: 'CameraSubscriberDisableAudio',
  screenSubscriberEnableVideo: 'ScreenSubscriberEnableVideo',
  screenSubscriberEnableAudio: 'ScreenSubscriberEnableAudio',
  screenSubscriberDisableVideo: 'ScreenSubscriberDisableVideo',
  screenSubscriberDisableAudio: 'ScreenSubscriberDisableAudio',
  initPreviewPublisher: 'InitPreviewPublisher',
  destroyPreviewPublisher: 'DestroyPreviewPublisher',
  previewPublisherEnableVideo: 'PreviewPublisherEnableVideo',
  previewPublisherEnableAudio: 'PreviewPublisherEnableAudio',
  previewPublisherDisableVideo: 'PreviewPublisherDisableVideo',
  previewPublisherDisableAudio: 'PreviewPublisherDisableAudio',
  setVideoDevice: 'SetVideoDevice',
  setAudioDevice: 'SetAudioDevice',
  getAudioOutputDevices: 'GetAudioOutputDevices',
  getActiveAudioOutputDevice: 'GetActiveAudioOutputDevice',
  getVideoDevice: 'GetVideoDevice',
  getAudioDevice: 'GetAudioDevice',
  getDevices: 'GetDevices',
  createCameraSubscriber: 'CreateCameraSubscriber',
  createScreenSubscriber: 'CreateScreenSubscriber',
  setProxyUrl: 'SetProxyUrl',
  setAudioOutputDevice: 'SetAudioOutputDevice',
  setVideoFilter: 'SetVideoFilter',
  clearVideoFilter: 'ClearVideoFilter',
  setEncryptionSecret: 'SetEncryptionSecret',
} as const;

type AnalyticsProps = {
  source?: string;
  sessionId?: string;
  connectionId?: string;
  apiKey?: string;
};

type AnalyticsOptions = {
  proxyUrl?: string;
};

type LogAction = typeof logAction[keyof typeof logAction];
type LogVariation = typeof logVariation[keyof typeof logVariation];

const defaultProps: AnalyticsProps = {
  source: window.location.origin,
};

class Analytics {
  analytics: OTKAnalytics;

  constructor(analyticsProps: AnalyticsProps = defaultProps) {
    const {
      source = window.location.origin,
      sessionId,
      connectionId,
      apiKey,
    } = analyticsProps;

    const otkanalyticsData = {
      clientVersion: process.env.CLIENT_VERSION || 'video-express-x.y.z',
      source,
      componentId: 'video-express',
      name: 'video-express',
      partnerId: apiKey,
      sessionId,
    };

    const options: AnalyticsOptions = {};
    const proxyUrl: string = ConfigStore.getProxyUrl();

    // Make sure to proxy VE-specific logs too
    if (proxyUrl) {
      options.proxyUrl = proxyUrl;
    }

    this.analytics = new OTKAnalytics(otkanalyticsData, options);

    if (sessionId && connectionId && apiKey) {
      this.update(sessionId, connectionId, apiKey);
    }
  }

  update = (
    sessionId: string | undefined,
    connectionId: string,
    apiKey: string
  ) => {
    if (sessionId && connectionId && apiKey) {
      const sessionInfo = {
        sessionId,
        connectionId,
        partnerId: apiKey,
      };
      this.analytics.addSessionInfo(sessionInfo);
    }
  };

  log = (action: LogAction, variation: LogVariation, payload?: any) => {
    if (!process.env.ENABLE_LOGGING) {
      return;
    }
    const data: {
      action: LogAction;
      variation: LogVariation;
      payload?: any;
    } = {
      action,
      variation,
    };
    if (payload) {
      data.payload = payload;
    }
    this.analytics.logEvent(data);
  };
}
export { Analytics, logVariation, logAction };
